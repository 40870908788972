import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function GrantsVsLoans () {
  const navigation = useNavigation();
  
  // Add refs for each section
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);
  const section5 = React.useRef(null);
  const section6 = React.useRef(null);
  const section7 = React.useRef(null);
  const conclusion = React.useRef(null);

  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/TopFunding/topFunding1.png")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Top Funding Sources for Canadian Startups and Entrepreneurs</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
                Finding the right funding is one of the most critical steps for Canadian startups and entrepreneurs. Canada offers a wealth of financial resources, from government grants and venture capital to crowdfunding and business loans. Each funding source has its benefits and caters to different stages of business development, whether you're launching a startup or scaling up an established business. Knowing how to navigate these options can provide the financial backing necessary to fuel your growth and bring your ideas to market.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(section1)}>
                    <Text style={styles.tocItem}>1. Government Grants and Programs</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section2)}>
                    <Text style={styles.tocItem}>2. Venture Capital and Angel Investors</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section3)}>
                    <Text style={styles.tocItem}>3. Incubators and Accelerators</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section4)}>
                    <Text style={styles.tocItem}>4. Crowdfunding</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section5)}>
                    <Text style={styles.tocItem}>5. Business Loans</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section6)}>
                    <Text style={styles.tocItem}>6. Angel Networks and Investment Groups</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section7)}>
                    <Text style={styles.tocItem}>7. Corporate Investment and Strategic Partnerships</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(conclusion)}>
                    <Text style={styles.tocItem}>8. Conclusion</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Section 1 */}
                <View ref={section1} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopFunding/topFunding2.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Government Grants and Programs</Text>
                    <Text style={styles.paragraph}>
                        Canadian entrepreneurs have access to a range of government-backed grants and funding programs designed to encourage business development and innovation. One prominent program is the Canada Small Business Financing Program (CSBFP), which supports startups by offering loans to purchase assets or improve facilities. Another notable resource is Futurpreneur Canada, which provides funding, mentorship, and support to young entrepreneurs aged 18 to 39. They offer start-up loans of up to $60,000 along with business coaching.
                    </Text>
                  </View>
                </View>

                {/* Section 2 */}
                <View ref={section2} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopFunding/topFunding3.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Venture Capital and Angel Investors</Text>
                    <Text style={styles.paragraph}>
                        Venture capital (VC) and angel investors are critical sources of funding for startups with high growth potential. VC firms such as BDC Capital, OMERS Ventures, and Real Ventures invest in Canadian startups, particularly those in the technology, healthcare, and cleantech sectors. These firms provide capital in exchange for equity, enabling businesses to scale quickly.
                    </Text>
                  </View>
                </View>

                {/* Section 3 */}
                <View ref={section3} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopFunding/topFunding4.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Incubators and Accelerators</Text>
                    <Text style={styles.paragraph}>
                        Incubators and accelerators offer startups more than just financial support—they provide mentorship, networking opportunities, and workspace. Some of Canada's leading accelerators, such as MaRS Discovery District and Creative Destruction Lab, specialize in helping startups navigate early growth stages. These programs often culminate in pitch events where startups can present their business to a group of investors. Accelerators typically offer seed funding in exchange for equity, but the value of their networks and guidance often outweighs the cost.
                    </Text>
                </View>
                </View>

                {/* Section 4 */}
                <View ref={section4} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopFunding/topFunding5.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Crowdfunding</Text>
                    <Text style={styles.paragraph}>
                        Crowdfunding has become a popular funding avenue for entrepreneurs looking to raise small amounts from a large group of people. Platforms like Kickstarter, Indiegogo, and GoFundMe allow startups to pitch their product or service directly to consumers. In exchange for small contributions, backers may receive early access to products or exclusive offers. Crowdfunding is particularly useful for validating demand, building a customer base, and securing early-stage funding without giving up equity.
                    </Text>
                  </View>
                </View>

                {/* Section 5 */}
                <View ref={section5} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopFunding/topFunding6.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Business Loans</Text>
                    <Text style={styles.paragraph}>
                        For startups that require predictable funding without giving up equity, business loans are a reliable option. Government-backed loans, such as those provided by the Business Development Bank of Canada (BDC), cater specifically to entrepreneurs and startups. BDC offers loans that cover startup costs, working capital, and business expansion. The interest rates are typically favorable, and the terms can be more flexible compared to traditional bank loans.
                        Canadian banks, such as RBC, TD, and CIBC, also provide business loans tailored to startups, often requiring a solid business plan and good credit history. These loans help entrepreneurs fund everything from inventory to marketing and can be used for day-to-day operations or long-term growth initiatives.                    
                    </Text>
                    </View>
                </View>

                {/* Section 6 */}
                <View ref={section6} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopFunding/topFunding7.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Angel Networks and Investment Groups</Text>
                    <Text style={styles.paragraph}>
                        In addition to venture capital and private angel investors, there are also formalized networks of investors who specialize in providing early-stage funding to Canadian startups. Angel Investment Networks like Golden Triangle Angel Network (GTAN) and Ontario Network of Entrepreneurs (ONE) are platforms that connect startups with angel investors. These networks can offer both the funding needed to grow a business and the guidance required to navigate the complexities of startup development.
                        Angel networks are particularly valuable because they often include mentorship, introductions to other investors, and a wealth of industry experience. Startups that receive angel funding typically benefit from having experienced investors who offer strategic advice alongside capital.
                    </Text>
                  </View>
                </View>

                {/* Section 7 */}
                <View ref={section7} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopFunding/topFunding8.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Corporate Investment and Strategic Partnerships</Text>
                    <Text style={styles.paragraph}>
                        Many large corporations in Canada are increasingly looking to invest in or partner with startups that align with their strategic goals. Programs like RBC Ventures or Telus Ventures are examples of corporate ventures that invest in innovative startups, particularly in areas like fintech, health, and technology. In these partnerships, corporations may provide funding, resources, and access to their customer base in exchange for equity or collaborative development. Corporate funding can be particularly beneficial for startups seeking rapid scalability through strategic alliances with established brands.
                    </Text>
                    </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View ref={conclusion} style={styles.conclusionContainer}>
                <View style={styles.conclusionBanner}>
                    <Image 
                    source={require("../../../assets/blogs/GrantsVsLoan/grantLoan4.webp")} 
                    style={styles.conclusionBannerImage}
                    />
                    <View style={styles.conclusionBannerOverlay}>
                    <Text style={styles.conclusionBannerText}>CONCLUSION</Text>
                    </View>
                </View>
                
                <View style={styles.conclusionContent}>
                    <Text style={[styles.paragraph, styles.centeredText]}>
                        Canadian startups and entrepreneurs have access to a diverse range of funding sources that can help turn their ideas into successful businesses. By leveraging government grants, venture capital, incubators, crowdfunding, and business loans, entrepreneurs can secure the financial support they need to grow and thrive in Canada’s competitive business landscape. Understanding the different funding options available and how to apply for them is crucial for navigating the early stages of business development and expansion. With careful planning and the right funding, Canadian entrepreneurs can set themselves up for long-term success.
                    </Text>
                </View>

              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For a comprehensive guide on funding sources tailored to Canadian startups, you can visit,{' '}
                        <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                        <Text style={styles.ctaLink}>FindGrant.ca</Text>
                        </TouchableOpacity> where they provide access to a variety of grants and funding options for entrepreneurs, covering government grants, private funding, and venture capital.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});
