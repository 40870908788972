import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;

export default function EnvironmentalBusinessGrants() {
  const navigation = useNavigation();
  
  // Add refs for each section
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);
  const conclusion = React.useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={styles.container}>
          {/* Banner */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/SmallBusiness/smallBusinessGrants4.png")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Small Business Grants in Canada: What You Need to Know</Text>
            </View>
          </View>

          {/* Intro section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
                Small businesses are vital to Canada’s economy, driving innovation, creating jobs, and supporting local communities. For those looking to start or grow a small business, securing funding through grants can be a game-changer. These grants, funded by federal, provincial, and local governments, as well as non-profit organizations, often don’t need to be repaid, making them an attractive alternative to loans or investments. If you’re curious about what types of grants exist and how to qualify, here’s what you need to know about small business grants in Canada for 2024.
            </Text>
          </View>

          <View style={styles.content}>
          <View style={styles.twoColumnLayout}>
                {/* Left Column */}
                <View style={styles.leftColumn}>
                    {/* Table of Contents */}
                    <View style={styles.tocContainer}>
                        <Text style={styles.tocTitle}>Table of contents</Text>
                        <TouchableOpacity onPress={() => scrollToSection(section1)}>
                            <Text style={styles.tocItem}>1. Why Small Business Grants Matter</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(section2)}>
                            <Text style={styles.tocItem}>2. Types of Grants for Small Businesses</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(section3)}>
                            <Text style={styles.tocItem}>3. Eligibility and How to Apply</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(section4)}>
                            <Text style={styles.tocItem}>4. Making the Most of Grant Opportunities</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(conclusion)}>
                            <Text style={styles.tocItem}>5. Conclusion</Text>
                        </TouchableOpacity>
                    </View>

                  {/* Related Articles */}
                  <View style={styles.relatedContainer}>
                    <Text style={styles.relatedTitle}>Related Articles</Text>
                    <TouchableOpacity 
                      style={styles.relatedArticle}
                      onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                    >
                      <Image 
                        style={styles.relatedImage} 
                        source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                      />
                      <Text style={styles.relatedText}>
                        Environmental Business Grants: How to Fund Your Green Business in Canada
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity 
                      style={styles.relatedArticle}
                      onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                    >
                      <Image 
                        style={styles.relatedImage} 
                        source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                      />
                      <Text style={styles.relatedText}>
                        Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                      </Text>
                    </TouchableOpacity>
                  </View>
              </View>

                {/* Right Column - Main Content */}
                <View style={styles.rightColumn}>
                    {/* Section 1*/}
                    <View ref={section1} style={[styles.section, styles.flexRowReverse]}>
                    <Image style={styles.sideImage} source={require("../../../assets/blogs/SmallBusiness/smallBusinessGrants5.png")} />
                    <View style={styles.sideContent}>
                        <Text style={styles.sectionTitle}>Why Small Business Grants Matter</Text>
                        <Text style={styles.paragraph}>
                        The costs of running a business can be significant, covering everything from startup expenses to marketing, hiring, and product development. Grants provide financial support without adding debt, enabling businesses to flourish without the burden of repayment. The wide range of grants available targets various goals, including business startups, industry-specific growth, training, research, and expansion.
                        </Text>                                   
                    </View>
                </View>

                  {/* Section 2 */}
                  <View ref={section2} style={[styles.section, styles.flexRow]}>
                    <Image style={styles.sideImage} source={require("../../../assets/blogs/SmallBusiness/smallBusinessGrants1.png")} />
                    <View style={styles.sideContent}>
                        <Text style={styles.sectionTitle}>Types of Grants for Small Businesses</Text>
                        <Text style={styles.paragraph}>
                        Canada has a variety of grants catering to different business needs. Startup grants provide initial funding for those in the early stages of developing a business. Futurpreneur Canada, for instance, supports young entrepreneurs with funding, mentorship, and resources. The Canadian Business Innovation Program (CBIP) also helps startups focused on research and innovation.
                        </Text>
                        <Text style={styles.paragraph}>
                        For those operating in specialized sectors, industry-specific grants are available. For example, the Agricultural Clean Technology Program offers funding for agri-businesses to implement sustainable practices, while Innovative Solutions Canada (ISC) supports tech startups that develop innovative solutions to government challenges.
                        </Text>
                                                           
                    </View>  
                  </View>
                <View style={[styles.section]}>
                    <Text style={styles.paragraph}>
                        Businesses that are expanding can benefit from scale-up grants like the Business Scale-Up and Productivity Program, which assists high-growth companies in improving productivity and accessing new markets. FedDev Ontario is another resource supporting businesses in Southern Ontario with plans to innovate and commercialize new services.
                    </Text>
                    <Text style={styles.paragraph}>
                        Hiring and training are also costly for small businesses, which is where hiring and training grants like the Canada Job Grant come into play. This program supports employers in training their workforce, varying slightly by province. The Youth Employment Program is another option that funds hiring young Canadians for roles in research and development.
                    </Text>
                    <Text style={styles.paragraph}>
                        Lastly, there are research and development grants such as the National Research Council of Canada Industrial Research Assistance Program (NRC IRAP), which funds the development of innovative technologies. Additionally, the Scientific Research and Experimental Development (SR&ED) tax incentive offers credits or refunds for businesses investing in R&D.
                    </Text> 
                </View>
                    
                </View>
                </View>

                    <View style={styles.sectionContainer}>
                      <View style={[styles.section, styles.flexRowReverse]} ref={section3}>
                        <Image 
                          style={styles.sideImage} 
                          source={require("../../../assets/blogs/SmallBusiness/smallBusinessGrants2.png")}
                        />
                        <View style={styles.sideContent}>
                          <Text style={styles.sectionTitle}>Eligibility and How to Apply</Text>
                            <Text style={styles.paragraph}>
                            While there is an array of grants available, each has its own set of eligibility criteria. Most grants are region-specific, meaning your business must be located in a particular province or municipality to qualify. The size of your business, often measured by employees or revenue, also plays a role in eligibility, as many grants target businesses with fewer than 500 employees.
                            </Text>
                            <Text style={styles.paragraph}>
                            Aligning your business type with the grant's industry focus is crucial. A grant aimed at clean tech will have different criteria than one for agricultural startups. Additionally, it’s important to clearly outline how you plan to use the funds. A solid business plan, complete with goals, market analysis, and financial projections, is often required to help grant providers understand the impact of their investment.
                            </Text>
                            <Text style={styles.paragraph}>
                            To increase your chances of securing a grant, research thoroughly on platforms like the Canada Business Network or Innovation Canada. Tailor your application to match the objectives of the grant, provide all required documents, and apply as early as possible. Following up after submitting your application can demonstrate commitment and keep your business top-of-mind.
                            </Text>
                        </View>
                      </View>

                      <View style={[styles.section, styles.flexRow]} ref={section4}>
                        <Image 
                          style={styles.sideImage} 
                          source={require("../../../assets/blogs/SmallBusiness/smallBusinessGrants3.png")}
                        />
                        <View style={styles.sideContent}>
                          <Text style={styles.sectionTitle}>Making the Most of Grant Opportunities</Text>
                          <Text style={styles.paragraph}>
                            Small business grants in Canada offer invaluable support for startups, expansions, innovation, and more. Whether you’re just getting started or looking to grow, exploring the various grant opportunities available can provide the funding needed to move your business forward. By understanding eligibility requirements and submitting a strong, well-researched application, you can tap into these resources and propel your small business to greater success in 2024.
                          </Text>
                        </View>
                      </View>
          </View>

            

                        {/* <View style={styles.section}>
                            <Text style={styles.text}>
                            For information on funding your green business through environmental grants in Canada, a targeted resource is available at FindGrant.ca's Environmental Business Grants section.
                            This page offers insights into various grants, including those supporting sustainable practices, clean technology, EV infrastructure, and other eco-friendly initiatives.
                            </Text>
                        </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For small business owners in Canada seeking grant funding,{' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/SmallBusinessGrants')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity> provides a range of resources tailored to help you explore grants, loans, and other financial support options.
                        </Text>
                    </View>
                        </View>
                    </View>
            <Footer/>
        </ScrollView>
    </>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  headerSection: {
    paddingTop: 10,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 768 ? 'column' : 'row',
    flex: 1,
    paddingTop: 20,
  },
  leftColumn: {
    width: screenWidth < 768 ? '100%' : '25%',
    paddingRight: screenWidth < 768 ? 0 : 20,
    paddingLeft: 20,
    marginBottom: screenWidth < 768 ? 20 : 0,
  },
  rightColumn: {
    width: screenWidth < 768 ? '100%' : '75%',
    paddingRight: 20,
  },
  sectionContainer:{
    padding: 0,
    paddingHorizontal: 0,
  },
  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    marginBottom: 16,

    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  text: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  introText: {
    fontSize: 16,
    lineHeight: 24,  
    padding: screenWidth < 700  ? 0: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 10,
    color: '#011F6F',
    textAlign: 'justify',
  },
  headerImage: {
    width: '100%',
    height: 200,
    marginBottom: 16,
    borderRadius: 8,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },

  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#011F6F',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 14,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedArticlesContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#011F6F',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 15,
  },
  relatedImage: {
    width: '100%',
    height: 120,
    borderRadius: 8,
    marginBottom: 8,
  },
  relatedText: {
    fontSize: 14,
    color: '#011F6F',
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
});