import React, { useState } from "react";
import { View, StyleSheet, Text, Dimensions } from "react-native";
import { CheckBox } from "react-native-web";

const screenWidth = Dimensions.get("window").width;

const AllGrantButton=()=>{


  const [isChecked, setIsChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);  // State to handle hover effect

  return (
    <View style={styles.OuterContainer}>
      <View style={styles.InnerContainer}>
        <CheckBox
            value={isChecked}
            onValueChange={setIsChecked}
        />
        <Text style={styles.text}>All Grants</Text>

        {/* Help Icon with Tooltip */}
        <View
          style={styles.helpIconContainer}
          onMouseEnter={() => setIsHovered(true)}  // Trigger when hovering over the icon
          onMouseLeave={() => setIsHovered(false)}  // Trigger when the hover ends
        >
          <Text style={styles.helpIcon}>?</Text>
          {isHovered && (
            <View style={styles.tooltip}>
              <Text style={styles.tooltipText}>
                "All Grants means that <Text style={styles.bold}>Show All</Text> eligible grants for the search.
                E.g., A woman applicant sees all <Text style={styles.bold}>Open </Text>grants including non-women grants.
                Unchecking this box means only <Text style={styles.bold}>Specific</Text> grants for women are shown."
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
    )

}

const styles=StyleSheet.create({
  OuterContainer:{
    width: 140,
    height: 60,
    borderRadius: 70,  
    backgroundColor: "rgb(0,32,113)",        
    position: 'relative',
    zIndex: 900,
  },
  InnerContainer:{
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 15,    
    alignItems: 'center',
    justifyContent: 'center',
  },
  text:{
    textAlign: 'center',
    fontSize: 18,
    color: "#FFFFFF",
    marginLeft: 10,
  },
  
  helpIconContainer: {
    position: "absolute",  // To position the tooltip correctly
    marginLeft: 20,
    right: -20,
    top: 20,
    backgroundColor: 'green',
    borderRadius: "50%",
    width: 18,
    height: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  helpIcon: {
    fontSize: 20,
    color: "#FFFFFF",
    cursor: "pointer", // Show a pointer when hovering over the icon
  },
  tooltip: {
    position: "absolute",
    top: screenWidth < 850 ? 41 : 43,
    left: screenWidth < 850 ? -65 : -58 ,
    transform: [{ translateX: -100 }],
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    backgroundColor: "rgb(0,32,113)",   
    padding: 10,
    borderRadius: 5,
    maxWidth: 280,
    zIndex: 1000,
    flexWrap: "wrap",
    display: 'flex',
    // alignSelf: "center",
  },
  tooltipText: {
    color: "#fff",
    fontSize: screenWidth < 850 ? 10 : 12,
    textAlign: "center",
    width: screenWidth < 850 ? 165 : 210,
    textAlign: "justify",
  },
  bold: {
    fontWeight: 'bold',  // Makes the text bold
  },
})

export default AllGrantButton;