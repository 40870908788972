import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Pressable,
  Dimensions,
} from "react-native";
import React, {useState, useEffect}  from "react";
import SaveIcon from "./SaveIcon";
import { useNavigation } from "@react-navigation/native";
import Layout from "./Layout.jsx";
import Header from "./Header.jsx";
import HeroGrant from "../components/HeroGrants.jsx";
import Footer from "./Footer.jsx";

const screenWidth = Dimensions.get("window").width;

const ListGrantsDropDown = ({ category, grantsData, fetchedData }) => {
  const navigation = useNavigation();
  const headers = ["Funding Program", "Funding Source", "Description"];

  console.log({grantsData});

  // State to track whether the icon is pressed or not
  const [isPressed, setIsPressed] = useState(false);

  // State to track which icon is pressed (using an object or array)
  const [pressedState, setPressedState] = useState({});

  // Toggle the color when the Pressable is pressed
  // const handlePress = () => {
  //   setIsPressed(!isPressed); // Toggle the pressed state
  // };

  // Toggle icon color for a specific row
  const handlePress = (rowIndex) => {
    setPressedState((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex], // Toggle the pressed state for the specific row
    }));
  };

  return (
    <Layout>
      <Header />
      <HeroGrant category={category} />
      <View>
        {/* <ScrollView */}
          {/* horizontal */}
          {/* contentContainerStyle={{ paddingHorizontal: 10 }} */}
          {/* > */}
          <View style={styles.container}>
            <View>
              <Text style={styles.title}>List of Grants</Text>
            </View>
            <View style={styles.table}>
              {/* Header */}
              <View style={styles.headerContainer}>
                <View style={[styles.row, styles.headerRow]}>
                  {headers.map((header, index) => (
                    <View key={index} style={[styles.cell, styles.headerCell]}>
                      <Text style={[styles.text, styles.headerText]}>
                        {header}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>

              {/* Body */}
              {screenWidth < 700 ?  (
                <View style={styles.bodyContainer}>
                  {grantsData.map((row, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={[
                        styles.row,
                        rowIndex === grantsData.length - 1 && {
                          borderBottomWidth: 0,
                        },
                      ]}
                    >
                      {/* Program Name */}
                      <View style={styles.cell}>
                        <View style={styles.nameCell} >
                          <Pressable
                            onPress={() =>
                              navigation.navigate("GrantPageDetails", {
                                // short_url: row[0] || "N/A",
                                short_url: row[3] || "N/A",
                              })
                            }
                            style= {styles.programName}
                          >
                            <Text style={[styles.text, { color: "#011F6F" }, styles.text_program]}>
                              <strong>{row[0] || "N/A"}</strong>
                            </Text>
                          </Pressable>
                          <View style={styles.iconCell1}>
                            <Pressable 
                              key={rowIndex}
                              onPress={() => handlePress(rowIndex)}
                            >
                              <View style={styles.iconCell1}>
                                <SaveIcon width={24} height={27} color="#011F6F"/>
                                {/* <SaveIcon 
                                  width={24} 
                                  height={27} 
                                  color={pressedState[rowIndex] ? 'green' : '#011F6F'} // Change color based on pressedState for that row
                                  style={{ fill: pressedState[rowIndex] ? 'green' : 'none' }} // Use style to control the fill
                                /> */}
                              </View>
                            </Pressable>
                          </View>
                        </View>
                      </View>
                      {/* Program Type */}
                      <View style={styles.cell}>
                        <Text style={styles.text}>{row[1] || "N/A"}</Text>
                      </View>
                      {/* Description */}
                      <View style={styles.cell}>
                        <Text style={styles.text} numberOfLines={2}>{row[2] || "N/A"}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              ) : (
                <View style={styles.bodyContainer}>
                  {grantsData.map((row, rowIndex) => (
                    <View
                      key={rowIndex}
                      style={[
                        styles.row,
                        rowIndex === grantsData.length - 1 && {
                          borderBottomWidth: 0,
                        },
                      ]}
                    >
                      {/* Program Name */}
                      <View style={styles.cell}>
                        <Pressable
                          onPress={() =>
                            navigation.navigate("GrantPageDetails", {
                                // short_url: row[0] || "N/A",
                                short_url: row[3] || "N/A",
                            })
                          }
                        >
                          <Text style={[styles.text, { color: "#011F6F" }, styles.text_program]}>
                            <strong>{row[0] || "N/A"}</strong>
                          </Text>
                        </Pressable>
                      </View>
                      {/* Program Type */}
                      <View style={styles.cell}>
                        <Text style={styles.text}>{row[1] || "N/A"}</Text>
                      </View>
                      {/* Description */}
                      <View style={styles.cell}>
                        <Text style={styles.text} numberOfLines={2}>{row[2] || "N/A"}</Text>
                      </View>
                      {/* Save Icon */}
                      <View style={styles.iconCell}>
                        <Pressable 
                          key={rowIndex}
                          onPress={() => handlePress(rowIndex)}
                        >
                          <View style={styles.iconCell1}>
                            <SaveIcon width={24} height={27} color="#011F6F"/>
                            {/* <SaveIcon 
                              width={24} 
                              height={27} 
                              color={pressedState[rowIndex] ? 'green' : '#011F6F'} // Change color based on pressedState for that row
                              style={{ fill: pressedState[rowIndex] ? 'green' : 'none' }} // Use style to control the fill
                            /> */}
                          </View>
                        </Pressable>
                      </View>
                    </View>
                  ))}
                </View>
              )}
            </View>
          </View>
        {/* </ScrollView> */}
      </View>
      <Footer />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {    
    width: "100%",
    // width: "65%",
    justifyContent: "center",    
    alignItems: "flex-start",
    textAlign: "left",
    marginLeft: "auto",
    marginRight: "auto",    
    marginBottom: 55,
    paddingHorizontal:
      screenWidth < 700
        ? "3vw"
        : screenWidth < 821
        ? "3vw"
        : screenWidth < 1057
        ? 30
        : screenWidth < 1280
        ? 33
        : 45, 
    flexWrap: "wrap",
    backgroundColor: "#FFF",
  },
  title: {
    fontFamily: "OpenSans-Bold",
    fontSize: screenWidth < 700 ? 24 : 24,
    color: "#011F6F",
    marginBottom: 10,
    paddingLeft: 20,
  },
  table: {
    // margin: 10,
    padding: 20,
    backgroundColor: "#E2E7F5",
    borderRadius: 60,
    width: "100%",
    flexDirection: "column",
  },
  headerContainer: {},
  bodyContainer: {
    borderRadius: 40,
    overflow: "hidden",
  },
  headerRow: {
    backgroundColor: "#E2E7F5",
    borderBottomWidth: 0,
    flexDirection: screenWidth < 700 ? "column" : "row",
    justifyContent: "flex-start",
    flexWrap: "wrap", // Wrap content for small screens
    // backgroundColor: "red",
  },
  row: {
    flexDirection: screenWidth < 700 ? "column" : "row",
    backgroundColor: "#fff",
    padding: 10,
    paddingBottom: screenWidth < 580 ? 20 : 10,
    borderBottomWidth: 1,
    borderBottomColor: "#EBEBEB",    
    flexWrap: "wrap", // Allow wrapping for smaller screens    
    // backgroundColor: "red",
  },
  cell: {
    padding: screenWidth < 700 ? 5 : 10, //Spacing between cells
    paddingTop: screenWidth < 700 ? 10 : 10,    
    paddingLeft: screenWidth < 700 ? null : 40,
    paddingHorizontal: screenWidth < 700 ? 20 : null,
    minWidth: 100,
    flex: screenWidth < 700 ? null : 1,
    alignItems: "flex-start",
    textAlign: "center",  
    justifyContent: null,
    alignItems: screenWidth < 700 ? "flex-start" : null,
    flexWrap: "wrap", // Allow wrapping for smaller screens
    // backgroundColor: "blue",
  },

  nameCell:{
    width: "100%",
    flexWrap: "wrap", //
    flexDirection: "row",
    justifyContent: "space-between",
  },
  programName:{
    minWidth: 100,
    width: "83%",
    flexWrap: 'wrap', 
    alignItems: "flex-start",
  },
  iconCell1: {
    justifyContent: "center",
  },

  iconCell: {
    paddingTop: 15,
    paddingRight: 40,
    paddingBottom: screenWidth < 700 ? 0 : 30,    
    paddingLeft: screenWidth < 700 ? 18 : 30,
  },
  headerCell: {
    alignItems: "flex-start",
    justifyContent: "center",
    padding: 10,
    minWidth: 100,
    paddingLeft: 40,
    paddingRight: screenWidth < 700 ? 50 : null,
    flex: 1,
  },
  text: {    
    fontSize: screenWidth < 700 ? 16 : 16,
    fontFamily: "OpenSans-Regular",
    fontWeight: "400",
    color: "#000",
    flexWrap: "wrap",
    textAlign: "left",    
  },
  text_program: {    
    fontSize: screenWidth < 700 ? 17 : 16,
  },
  headerText: {
    fontSize: 18,
    fontWeight: "600",
    color: "#011F6F",
    fontFamily: "OpenSans-Regular",
    textAlign: "left",
    alignSelf: screenWidth < 700 ? "center" : null,
  },
});

export default ListGrantsDropDown;
