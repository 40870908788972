import React, { useState, useRef, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Dimensions,
} from "react-native";

const screenWidth = Dimensions.get("window").width;

const DropDown = ({ title, placeholder, items, style, children, onChange, index, isMobile }) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const dropdownRef = useRef();

  const handleSearch = (text) => {
    setInputValue(text);
    const filteredData = items.filter((option) =>
      option.label.toLowerCase().startsWith(text.toLowerCase())
    );
    setFilteredItems(filteredData);
    setIsDropdownVisible(true); // Show dropdown when typing
  };

  const handleItemSelect = (option) => {
    setInputValue(option.label);
    setIsDropdownVisible(false); // Hide dropdown after selection

    //Propagate selected value to parent via onChange
    if (onChange) {
      onChange(option.label);
    }
  };

  // Handle clicks outside of the dropdown
  const handleClickOutside = (event) => {
    // Check if dropdownRef.current exists and is a DOM element
    if (dropdownRef.current && dropdownRef.current instanceof HTMLElement) {
      if (!dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    }
  };

  useEffect(() => {
    // Only add listener if we're in a browser environment
    if (typeof document !== 'undefined') {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  return (
    <View style={[styles.container, style]} ref={dropdownRef}>
      {/* Search and Select Input */}
      <View style={styles.placeholder}>
        <Text style={{ fontWeight: "bold" }}>{title}</Text>
        <TextInput
          value={inputValue}
          onChangeText={handleSearch}
          placeholder={placeholder}
          style={[styles.input, inputValue !== "" && styles.selectedText]}
          onFocus={() => {
            setIsDropdownVisible(true);
          }}
          onBlur={(e) => {
            setTimeout(() => {
              if (!dropdownRef.current?.contains(document.activeElement)) {
                setIsDropdownVisible(false);
              }
            }, 200);
          }}
        />
      </View>

      {/* Dropdown list */}
      {isDropdownVisible && (
        <View style={[
          styles.dropdown,
          isMobile ? styles.dropdownMobile : styles.dropdownDesktop
        ]}>
          <FlatList
            style={styles.flatList}
            data={inputValue === "" ? items : filteredItems}
            keyExtractor={(option) => option.id.toString()}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={[styles.dropdownItem, hoveredItem === item.id && styles.hoveredItem]}
                onPress={() => handleItemSelect(item)}
                onMouseEnter={() => setHoveredItem(item.id)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <Text>{item.label}</Text>
              </TouchableOpacity>
            )}
          />
        </View>
      )}

      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 220,
    minHeight: 70,
    position: 'relative',
  },
  placeholder: {
    paddingLeft: 25,
    paddingTop: 12,
    backgroundColor: "white",
    borderRadius: 35,
  },
  input: {
    paddingTop: 5,
    paddingBottom: 12,
    fontSize: 13,
    color: "grey",
    outlineStyle: "none",
  },
  dropdown: {
    backgroundColor: "#FFFFFF",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 1000,
  },
  dropdownMobile: {
    position: 'relative',
    marginTop: 5,
    width: '100%',
  },
  dropdownDesktop: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    marginTop: 5,
  },
  flatList: {
    maxHeight: 250,
  },
  dropdownItem: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  hoveredItem: {
    backgroundColor: "#f5bf4c",
  },
  selectedText: {
    fontSize: 16,
    fontWeight: "bold",
  },
});

export default DropDown;