import React from "react";
import { View, Text, Image, StyleSheet, Dimensions } from "react-native";

const screenWidth = Dimensions.get("window").width;
const isMobile = screenWidth < 768;

const Step = ({
  stepNumber,
  iconSource,
  description,
  isLastStep,
  isTextFirst,
}) => {
  return (
    <View style={[
      styles.step, 
      !isTextFirst ? styles.rowReverse : null,
      isMobile && styles.stepMobile
    ]}>
      <View style={[styles.stepContent, isMobile && styles.stepContentMobile]}>
        <Text style={styles.stepDescription}>{description}</Text>
      </View>
      <View style={[styles.stepIconContainer, isMobile && styles.stepIconContainerMobile]}>
        <View style={[styles.circle, isMobile && styles.circleMobile]}>
          <Text style={[styles.circleText, isMobile && styles.circleTextMobile]}>{stepNumber}</Text>
        </View>
        {!isLastStep && <View style={[styles.verticalLine, isMobile && styles.verticalLineMobile]}></View>}
      </View>
      <View style={[styles.imgContainer, isMobile && styles.imgContainerMobile]}>
        <Image source={iconSource} style={[styles.icon, isMobile && styles.iconMobile]} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  step: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  stepMobile: {
    marginBottom: 10,
  },
  rowReverse: {
    flexDirection: "row-reverse",
  },
  stepIconContainer: {
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "20%",
  },
  stepIconContainerMobile: {
    width: "15%",
  },
  circle: {
    width: 70,
    height: 70,
    borderRadius: 700,
    backgroundColor: "#011F6F",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  circleMobile: {
    width: 50,
    height: 50,
  },
  circleText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },
  circleTextMobile: {
    fontSize: 16,
  },
  imgContainer: {
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  imgContainerMobile: {
    width: "42.5%",
  },
  verticalLine: {
    position: "absolute",
    top: 40,
    width: 5,
    height: 180,
    backgroundColor: "#011F6F",
    zIndex: 0,
  },
  verticalLineMobile: {
    top: 25,
    height: 120,
    width: 3,
  },
  icon: {
    width: screenWidth < 881 ? 150 : screenWidth < 1261 ? 200 : screenWidth < 1080 ? 200 : 200,
    height: screenWidth < 881 ? 150 : screenWidth < 1261 ? 200 : screenWidth < 1080 ? 200 : 200,
  },
  iconMobile: {
    width: 100,
    height: 100,
  },
  stepContent: {
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  stepContentMobile: {
    width: "42.5%",
  },
  stepDescription: {
    fontFamily: "OpenSans-Regular",
    fontSize: isMobile ? 12 : (screenWidth < 881 ? '2vh' : screenWidth < 1261 ? 24 : screenWidth < 1080 ? 24 : 24),
    color: "#000",
    textAlign: "center",
  },
});

export default Step;