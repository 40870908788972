import { Pressable, StyleSheet, Dimensions, Text } from "react-native";
import React, { useEffect, useState } from "react";
import DropDown from "../components/SearchBarComponents/DropdownComponent";
import ListGrants from "../components/ListGrants";
import { Image, View } from "react-native-web";
import SearchBarForSearchProgram from "../components/SearchProgram/SearchBarForSearchProgram";
import GrantOptionsIcons from "../components/GrantOptionsIcons";
import ExactMatch from "../components/ExactMatch";
import { useRoute } from "@react-navigation/native";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Header from "../components/Header.jsx";
import FontAwesome from '@expo/vector-icons/FontAwesome';
// import AsyncStorage from '@react-native-async-storage/async-storage';

const screenWidth = Dimensions.get("window").width;

const ProgramSearch = () => {
  const route = useRoute(); // Hook to access parameters
  const { selectedValues = {} } = route.params || {}; // Selected data from SearchBar
  console.log("Received selected values:", selectedValues);
  const [dropdownData, setDropdownData] = useState({
    location: [],
    community: [],
    industry: [],
    grants: [],
  });

  const [match, setMatch] = useState(false);
  const [activeIcon, setActiveIcon] = useState(null);
  const [showPrograms, setShowPrograms] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [activeCard, setActiveCard] = useState(null);
  const [results, setResults] = useState({});
  const [selectedProgramType, setSelectedProgramType] = useState(null);


  // const [selectedFields, setSelectedFields] = useState(selectedValues);
  const [selectedFilters, setSelectedFilters] = useState({
    location: selectedValues.location || "",
    community: selectedValues.community || "",
    industry: selectedValues.industry || "",
    grants: selectedValues.grants || "",
  });

  const [filteredGrants, setFilteredGrants] = useState([]);

  // Function to load questions from the API
  const fetchQuestions = async () => {
    try {
      const response = await fetch(
        "https://api.findgrant.ca/webpage/load-questions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      // Map questions and save options to state
      const mappedData = data.reduce((acc, question) => {
        if (question.question === "My location is...") {
          acc.location = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "I am a...") {
          acc.community = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "My industry is...") {
          acc.industry = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "I am looking for...") {
          acc.grants = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        }
        return acc;
      }, {});

      setDropdownData(mappedData); // Save data in state
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const fetchFilteredGrants = async (filters) => {
    try {
      const formData = new URLSearchParams();

      if (filters.location) formData.append("location", filters.location);
      if (filters.community) formData.append("ethnicity", filters.community);
      if (filters.industry) formData.append("industry", filters.industry);
      if (filters.grants) formData.append("program_target", filters.grants);
      // formData.append("search_term", searchTerm);

      console.log('Sending data: ', formData);

      const response = await fetch(
        "https://api.findgrant.ca/webpage/form-submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
        }
      );

      const data = await response.json();
      console.log('Sending data: ', data.program_result);

      setFilteredGrants(data.program_result || []);
      setShowPrograms(true);

      // await AsyncStorage.setItem('results', JSON.stringify(data.program_result));
    } catch (error) {
      console.error("Error fetching filtered grants:", error);
    }
  };

  const handleFilterChange = async (field, value) => {
    const updatedFilters = { ...selectedFilters, [field]: value };
    setSelectedFilters(updatedFilters);
    console.log( "Filters updated", updatedFilters)
    await fetchFilteredGrants(updatedFilters);
  };

  const handleProgramClick = async (id, programType) => {
    // Toggle selection if clicked icon is already selected, otherwise select it
    const newSelectedProgram = programType === selectedProgramType ? null : programType;
    setSelectedProgramType(newSelectedProgram);
  
    const activeCardData = id === activeCard ? null : id;
    setActiveCard(activeCardData);
  
    const filters = { grants: newSelectedProgram };
    await fetchFilteredGrants(filters);
  };
  

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    fetchFilteredGrants(selectedFilters);
  }, []);

  return (
    <>
      <Layout>
        <Header />
          <View style={styles.main}>
          <View style={styles.findYourGrants}>
              <Text style={styles.findyourgrantstext}> 
                Find your grants now (pick at least 1 choice below) :
              </Text>
            </View>
            <View style={styles.try}>
                {screenWidth < 881 ? (
                    <View style={styles.container}>
                      <View style={[styles.searchContainer, { zIndex: 200 }]}>
                        {/* <DropDown/> */}
                        <View style={[styles.dropdown, { zIndex: 500 }]}>
                          <SearchBarForSearchProgram
                            style={{ borderRadius: 35 }}
                            title="Location"
                            placeholder="Select your current location"
                            items={dropdownData.location}
                            defaultValue={selectedValues.location} //
                            onChange={(value) => handleFilterChange("location", value)}
                          ></SearchBarForSearchProgram>

                          <SearchBarForSearchProgram
                            title="Community"
                            placeholder="Select your community group"
                            items={dropdownData.community}
                            defaultValue={selectedValues.community}
                            onChange={(value) => handleFilterChange("community", value)}
                          />

                          <SearchBarForSearchProgram
                            title="Industry"
                            placeholder="Select your industry"
                            items={dropdownData.industry}
                            defaultValue={selectedValues.industry}
                            onChange={(value) => handleFilterChange("industry", value)}
                          />

                          <SearchBarForSearchProgram
                            style={{ borderRadius: 35 }}
                            title="Kind of Grant"
                            placeholder="kind of grant you are looking for"
                            items={dropdownData.grants}
                            defaultValue={selectedValues.grants}
                            onChange={(value) => handleFilterChange("grants", value)}
                          />
                          
                          <View style={[styles.searchButtonContainer, styles.mobileSearchButton]}>
                            <Pressable 
                              style={styles.searchButton}
                              onPress={() => fetchFilteredGrants(selectedFilters)}
                            >
                              <FontAwesome name="search" size={26} color="white" />
                            </Pressable>
                          </View>
                        </View>
                      </View>
                    </View>
                ) : screenWidth < 1261 ? (
                  <View style={styles.container}>
                    <View style={[styles.searchContainer, { zIndex: 200 }]}>
                      <View style={[styles.dropdown, { zIndex: 500 }]}>
                        <SearchBarForSearchProgram
                          style={{ borderRadius: 35 }}
                          title="Location"
                          placeholder="Select your current location"
                          items={dropdownData.location}
                          defaultValue={selectedValues.location}
                          onChange={(value) => handleFilterChange("location", value)}
                        />

                        <SearchBarForSearchProgram
                          title="Community"
                          placeholder="Select your community group"
                          items={dropdownData.community}
                          defaultValue={selectedValues.community}
                          onChange={(value) => handleFilterChange("community", value)}
                        />
                      </View>

                      <View style={[styles.dropdown, { zIndex: 500 }]}>
                        <SearchBarForSearchProgram
                          title="Industry"
                          placeholder="Select your industry"
                          items={dropdownData.industry}
                          defaultValue={selectedValues.industry}
                          onChange={(value) => handleFilterChange("industry", value)}
                        />

                        <SearchBarForSearchProgram
                          style={{ borderRadius: 35 }}
                          title="Kind of Grant"
                          placeholder="kind of grant you are looking for"
                          items={dropdownData.grants}
                          defaultValue={selectedValues.grants}
                          onChange={(value) => handleFilterChange("grants", value)}
                        />
                      </View>

                      <View style={styles.searchButtonContainer}>
                        <Pressable 
                          style={styles.searchButton}
                          onPress={() => fetchFilteredGrants(selectedFilters)}
                        >
                          <FontAwesome name="search" size={26} color="white" />
                        </Pressable>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={[styles.container, {zIndex:150}]}>
                    <View style={[styles.dropdown, { zIndex: 500 }]}>
                      <SearchBarForSearchProgram
                        style={{ borderRadius: 35 }}
                        title="Location"
                        placeholder="Select your current location"
                        items={dropdownData.location}
                        defaultValue={selectedValues.location}
                        onChange={(value) => handleFilterChange("location", value)}
                        index={0}
                      />

                      <SearchBarForSearchProgram
                        title="Community"
                        placeholder="Select your community group"
                        items={dropdownData.community}
                        defaultValue={selectedValues.community}
                        onChange={(value) => handleFilterChange("community", value)}
                        index={1}
                      />

                      <SearchBarForSearchProgram
                        title="Industry"
                        placeholder="Select your industry"
                        items={dropdownData.industry}
                        defaultValue={selectedValues.industry}
                        onChange={(value) => handleFilterChange("industry", value)}
                        index={2}
                      />

                      <SearchBarForSearchProgram
                        style={{ borderRadius: 35 }}
                        title="Kind of Grant"
                        placeholder="kind of grant you are looking for"
                        items={dropdownData.grants}
                        defaultValue={selectedValues.grants}
                        onChange={(value) => handleFilterChange("grants", value)}
                        index={3}
                      />

                      <View style={styles.searchButtonContainer}>
                        <Pressable 
                          style={styles.searchButton}
                          onPress={() => fetchFilteredGrants(selectedFilters)}
                        >
                          <FontAwesome name="search" size={26} color="white" />
                        </Pressable>
                      </View>
                    </View>
                  </View>
                )}
                {(filteredGrants && Object.keys(filteredGrants).length > 0 &&
                  <View style={styles.grantOptions}>
                    <View style={styles.dividerUp}/>
                    <View style={styles.grantOptionsIconsContainer}>
                      <ExactMatch
                        setActiveIcon={setActiveIcon}
                        match={match}
                        setMatch={setMatch}
                        activeIcon={activeIcon}
                      />
                      <View style={styles.dividerMiddle}/>
                      <GrantOptionsIcons
                        setActiveIcon={setActiveIcon}
                        activeIcon={activeIcon}
                        onClick={() => {
                          handleProgramClick(item.id, item.text)
                        }}
                      />
                    </View>
                    <View style={styles.dividerDown}/>
                  </View>
                )}
                <View>
                  <ListGrants data={filteredGrants} />
                </View>
              </View>
          </View>
        <Footer />
      </Layout>
    </>
  );
};

export default ProgramSearch;

const styles = StyleSheet.create({
  main: {
    alignItems: "center",
    flexWrap: "wrap",
    paddingHorizontal: 20,
    marginHorizontal: 20,
  },
  try: {
    width: "100%",
    flexWrap: "wrap",
  },
  findYourGrants: {
    paddingTop: 20,
    width: screenWidth < 881
    ? "85%"
    : screenWidth < 1261
    ? 610
    : 1030,
  },
  findyourgrantstext:{
    fontSize: 16,
  },
  container: {
    display: "flex",
    marginVertical: screenWidth < 881 ? 15 : 30, // Reduced margin for mobile
    alignItems: "center",
    width: screenWidth < 700 ? "80%" : null,
    alignSelf: "center",
    borderRadius: 50,
    paddingRight: 40,
    paddingLeft: 40,
    paddingVertical: screenWidth < 881 ? 15 : 10, // Changed padding for desktop view
    opacity: "90%",
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderWidth: 2,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.6,
    shadowRadius: 10,
  },
  searchContainer: {
    gap: screenWidth < 881 ? 10 : 20, // Reduced gap for mobile
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: screenWidth < 881 ? 10 : 20, // Reduced margin for mobile
    width: "100%",
  },
  dropdown: {
    backgroundColor: "white",
    borderRadius: 35,
    flexDirection:
    screenWidth < 881
      ? "column"
      : screenWidth < 1261
      ? "column"
      : screenWidth < 1080
      ? "row"
      : "row",
    flexWrap: "wrap",
    textAlign: "left",
    ...(screenWidth < 881 && {
      alignItems: "center", // Center items in mobile view
      width: "100%", // Full width in mobile
      gap: 10, // Reduced gap between items
    }),
  },
  searchButton: {
    backgroundColor: "#011f6f",
    height: 50,
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  searchButtonContainer: {
    marginLeft: 10,
  },
  mobileSearchButton: {
    marginLeft: 0,
    marginTop: 15, // Reduced margin for mobile
    alignItems: "center",
    width: "100%", // Full width to help with centering
  },
  grantOptions: {
    flexWrap: "wrap",
    paddingRight: 20,
  },
  grantOptionsIconsContainer: {
    width: "100%",
    position: "relative",
    overflow: "visible",
    flexWrap: "wrap",
    alignItems: "center",
  },
  dividerUp: {
    width: "100%",
    height: 2,
    backgroundColor: "#ebebeb",
    marginBottom: 20,
  },
  dividerMiddle: {
    width: screenWidth < 1100 ? "100%" : "30vh",
    height: 2,
    backgroundColor: "#ebfbeb",
    marginVertical: 20,
  },
  dividerDown: {
    width: "100%",
    height: 2,
    backgroundColor: "#ebebeb",
    marginVertical: 20,
  },
  filterContainer: {
    height: 160,
  },
});

// function ConvertToProperty(data) {
//   return data.split(";").map((data, index) => ({
//     id: index + 1,
//     label: data.trim(),
//   }));
// }
