import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import Layout from "../components/Layout.jsx";
import ContactForm from "../components/ContactFormComponents/ContactForm.jsx";

const Contact = () => {
  return (
    <Layout>
      <Header />
      <View style={styles.heroContainer}>
        <Text style={styles.heroTitle}>Let's get in touch</Text>
        <Text style={styles.heroSubtitle}>
          We're excited to hear from you and let's start something special together.
        </Text>
      </View>
      <ContactForm />
      <Footer />
    </Layout>
  );
};

const styles = StyleSheet.create({
  heroContainer: {
    paddingHorizontal: "10%",
    paddingTop: 100,
    paddingBottom: 100,
    alignItems: "center",
  },
  heroTitle: {
    fontSize: 80,
    fontWeight: "bold",
    textAlign: "center",
  },
  heroSubtitle: {
    fontSize: 18,
    textAlign: "center",
    maxWidth: 500,
    marginTop: 20,
  }
});

export default Contact;