import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Image, Pressable, TextInput, ScrollView, Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import HeroSimpleTitle from "../components/HeroSimpleTitle.jsx";


const BlogIndex = () => {
  const navigation = useNavigation();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [blogsPerPage] = useState(6); // Show 6 blogs per page

  // Handle window resize
  useEffect(() => {
    const handleDimensionsChange = ({ window }) => {
      setWindowWidth(window.width);
    };

    Dimensions.addEventListener('change', handleDimensionsChange);
    return () => {
      Dimensions.removeEventListener('change', handleDimensionsChange);
    };
  }, []);

  // Blog data 
  const blogPosts = [
    { 
      id: 1, 
      title: "Women in Business: Grants and Funding Programs for Female Entrepreneurs in Canada",
      description: "Canada has seen a growing commitment to supporting female entrepreneurs, with a variety of grants and funding programs designed to empower women in business.",
      link: "WomenInBusiness",
      image: "../../assets/blogs/WomenInBusiness/womenGrants1.webp" 
    },
    { 
      id: 2, 
      title: "Top Resources for Finding Grants in Each Canadian Province",
      description: "Securing grants in Canada can significantly help fund businesses, education, or community projects. Each province offers a variety of resources to guide individuals and organizations through the process.",
      link: "TopResources",
      image: "../../assets/blogs/TopResources/topResources2.webp" 
    },
    {
      id: 3,
      title: "Top Indigenous Grants in Canada to Support Business and Community Growth",
      description: "Indigenous communities and entrepreneurs in Canada can access a wide range of grants designed to encourage business development, innovation, and community growth.",
      link: "TopIndigenousGrants",
      image: "../../assets/blogs/TopIndegenous/topIndigenous2.webp" 
    },
    {
      id: 4,
      title: "Top Government Grants Available for Canadians in 2024",
      description: "In 2024, Canadians have access to a wide array of government grants designed to help individuals, businesses, and organizations achieve their goals without the burden of debt.",
      link: "TopGovernmentGrants",
      image: "../../assets/blogs/TopGovernment/topGovernment5.png" 
    },
    {
      id: 5,
      title: "Top Funding Sources for Canadian Startups and Entrepreneurs",
      description: "Finding the right funding is one of the most critical steps for Canadian startups and entrepreneurs. Canada offers a wealth of financial resources, from government grants and venture capital to crowdfunding and business loans.",
      link: "TopFundingSources",
      image: "../../assets/blogs/TopFunding/topFunding1.png" 
    },
    {
      id: 6,
      title: "Step-by-Step Guide to Applying for Grants in Canada",
      description: "Applying for grants in Canada can be a rewarding process if done correctly. Whether you're seeking funding for a business, education, or community project, understanding how to navigate the application process is essential.",
      link: "GrantApplicationGuide",
      image: "../../assets/blogs/GrantApplicationsGuide/grantApplications1.png" 
    },
    {
      id: 7,
      title: "Five Common Mistakes to Avoid When Applying for Grants",
      description: "Grant applications can be competitive. Learn about the five most common mistakes applicants make and how to avoid them to increase your chances of success.",
      link: "FiveMistakes",
      image: "../../assets/blogs/fiveMistakes/fiveMistakes.jpg" 
    },
    {
      id: 8,
      title: "Educational Grants and Scholarships in Canada",
      description: "Discover the various educational grants and scholarships available to Canadian students at all levels of education.",
      link: "EducationGrants",
      image: "../../assets/blogs/EducationGrants/education_grants_4.png" 
    },
    {
      id: 9,
      title: "Environmental Business Grants in Canada",
      description: "Learn about grants specifically designed for businesses focused on environmental sustainability and green initiatives.",
      link: "EnvironmentalBusinessGrants",
      image: "../../assets/blogs/EnvironmentalGrants/environmental1.png" 
    },
    {
      id: 10,
      title: "Startup Grants Canada 2024",
      description: "A comprehensive guide to the latest startup grants available in Canada for 2024.",
      link: "StartupGrantsCanada2024",
      image: "../../assets/blogs/StartUps/startUp1.png" 
    },
    {
      id: 11,
      title: "Grants vs. Loans: Understanding the Difference",
      description: "An in-depth comparison of grants and loans to help you determine which funding option is right for your needs.",
      link: "GrantsVsLoans",
      image: "../../assets/blogs/GrantsVsLoan/grantsvsloans1.png" 
    },
    {
      id: 12,
      title: "Black Entrepreneurs Grants in Canada",
      description: "A guide to grants and funding opportunities specifically designed to support Black entrepreneurs in Canada.",
      link: "BlackEntrepreneursGrants",
      image: "../../assets/blogs/BlackEnterprenuers/blackEnter11.webp" 
    },
    {
        id: 13,
        title: "How to Find Grants for Non-Profit Organizations in Canada",
        description: "Finding grants for non-profit organizations in Canada is crucial for obtaining financial support for important community, social, or environmental initiatives.",
        link: "HowToFind",
        image: "../../assets/blogs/HowToFind/howToFind1.png" 
      },
      {
          id: 14,
          title: "Small Business Grants in Canada: What You Need to Know",
          description: "all businesses are vital to Canada’s economy, driving innovation, creating jobs, and supporting local communities.",
          link: "SmallBusinessGrantsPost",
          image: "../../assets/blogs/SmallBusiness/smallBusinessGrants4.png" 
        }
  ];


  // Get current blogs
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;

  // Filter blogs based on search query
  useEffect(() => {
    const filtered = blogPosts.filter(blog => 
      blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBlogs(filtered);
    setCurrentPage(1); // Reset to first page when searching
  }, [searchQuery]);

  // Get current blogs for display after filtering and pagination
  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  // Categories for the sidebar
  const categories = [
    { name: "Indigenous Grants", link: "Indigenous" },
    { name: "Start-Up Grants", link: "StartUp" },
    { name: "Black Entrepreneurship Grants", link: "BlackEntrepreneur" },
    { name: "Environmental Business Grants", link: "Environmental" },
    { name: "Technology & Innovation Grants", link: "Technology" },
    { name: "Art & Culture", link: "ArtCulture" },
    { name: "Small Business Grants", link: "SmallBusiness" },
    { name: "Woman-Owned Business Grants", link: "Women" },
  ];

  // Determine if we're on a mobile screen
  const isMobile = windowWidth < 768;

  return (
    <Layout>
      <Header />
      <HeroSimpleTitle title="Blogs" subtitle="Discover the latest insights and guidance on finding and securing grants in Canada." />      

      <View style={styles.container}>

        <View style={[styles.mainContent, isMobile && styles.mainContentMobile]}>
          <View style={[styles.sidebar, isMobile && styles.sidebarMobile]}>
            <View style={styles.searchContainer}>
              <TextInput
                style={styles.searchInput}
                placeholder="Search blogs..."
                value={searchQuery}
                onChangeText={setSearchQuery}
              />
            </View>
            
            <View style={styles.categoriesContainer}>
              <Text style={styles.categoryTitle}>Categories</Text>
              {categories.map((category, index) => (
                <Pressable 
                  key={index}
                  onPress={() => navigation.navigate(category.link)}
                >
                  <Text style={styles.categoryItem}>{category.name}</Text>
                </Pressable>
              ))}
            </View>
          </View>

          <View style={styles.blogContentContainer}>
            <ScrollView style={styles.blogCardsContainer}>
              <View style={styles.blogGrid}>
                {currentBlogs.map((blog) => (
                  <Pressable 
                    key={blog.id} 
                    style={[styles.blogCard, isMobile && styles.blogCardMobile]}
                    onPress={() => navigation.navigate(blog.link)}
                  >
                    <View style={styles.cardImageContainer}>
                      {/* Replace with actual image component */}
                      <Image source={blog.image} style={styles.cardImageContainer} resizeMode="cover" />
                    </View>
                    <View style={styles.cardContent}>
                      <Text style={styles.blogTitle}>{blog.title}</Text>
                      <Text style={styles.blogDescription} numberOfLines={3}>
                        {blog.description}
                      </Text>
                      <Text style={styles.readMore}>Read More →</Text>
                    </View>
                  </Pressable>
                ))}
              </View>
            </ScrollView>
            
            {/* Pagination Controls */}
            {filteredBlogs.length > blogsPerPage && (
              <View style={styles.paginationContainer}>
                <Pressable 
                  style={[styles.paginationButton, currentPage === 1 && styles.paginationButtonDisabled]}
                  onPress={() => currentPage > 1 && paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <Text style={styles.paginationButtonText}>Previous</Text>
                </Pressable>
                
                <View style={[styles.pageNumbers, isMobile && styles.pageNumbersMobile]}>
                  {Array.from({ length: totalPages }, (_, i) => i + 1)
                    // On mobile, limit the number of visible page numbers
                    .filter(number => !isMobile || 
                      (number === 1 || 
                       number === totalPages || 
                       (number >= currentPage - 1 && number <= currentPage + 1))
                    )
                    .map((number, index, array) => {
                      // Add ellipsis for skipped pages
                      const showEllipsis = isMobile && index > 0 && array[index - 1] !== number - 1;
                      
                      return (
                        <React.Fragment key={number}>
                          {showEllipsis && (
                            <View style={styles.ellipsis}>
                              <Text>...</Text>
                            </View>
                          )}
                          <Pressable
                            style={[
                              styles.pageNumber,
                              currentPage === number && styles.currentPageNumber
                            ]}
                            onPress={() => paginate(number)}
                          >
                            <Text 
                              style={[
                                styles.pageNumberText,
                                currentPage === number && styles.currentPageNumberText
                              ]}
                            >
                              {number}
                            </Text>
                          </Pressable>
                        </React.Fragment>
                      );
                    })}
                </View>
                
                <Pressable 
                  style={[styles.paginationButton, currentPage === totalPages && styles.paginationButtonDisabled]}
                  onPress={() => currentPage < totalPages && paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <Text style={styles.paginationButtonText}>Next</Text>
                </Pressable>
              </View>
            )}
          </View>
        </View>
      </View>
      <Footer />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: "#FFF",
  },
  pageHeader: {
    marginBottom: 30,
    alignItems: "center",
  },
  pageTitle: {
    fontSize: 32,
    fontWeight: "700",
    marginBottom: 10,
    textAlign: "center",
  },
  pageDescription: {
    fontSize: 16,
    textAlign: "center",
    maxWidth: 600,
    paddingHorizontal: 15,
  },
  mainContent: {
    flexDirection: "row",
    maxWidth: 1200,
    marginHorizontal: "auto",
  },
  mainContentMobile: {
    flexDirection: "column",
  },
  sidebar: {
    width: 280,
    marginRight: 30,
  },
  sidebarMobile: {
    width: "100%",
    marginRight: 0,
    marginBottom: 30,
  },
  blogContentContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  blogCardsContainer: {
    flex: 1,
  },
  searchContainer: {
    marginBottom: 30,
  },
  searchInput: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 6,
    padding: 12,
    fontSize: 16,
    backgroundColor: "#fff",
  },
  categoriesContainer: {
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: 15,
    borderWidth: 1,
    borderColor: "#eaeaea",
  },
  categoryTitle: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 15,
  },
  categoryItem: {
    fontSize: 16,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea",
  },
  blogGrid: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  blogCard: {
    width: "48%",
    backgroundColor: "#fff",
    borderRadius: 8,
    overflow: "hidden",
    marginBottom: 30,
    borderWidth: 1,
    borderColor: "#eaeaea",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  blogCardMobile: {
    width: "100%",
  },
  cardImageContainer: {
    width: "100%",
    height: 180,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  cardContent: {
    padding: 15,
  },
  blogTitle: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 10,
  },
  blogDescription: {
    fontSize: 14,
    color: "#666",
    marginBottom: 15,
    lineHeight: 20,
  },
  readMore: {
    color: "#011F6F",
    fontWeight: "600",
  },
  // Pagination styles
  paginationContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 40,
    flexWrap: "wrap",
  },
  paginationButton: {
    backgroundColor: "#F5BF4C",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 6,
    marginHorizontal: 5,
    marginVertical: 5,
  },
  paginationButtonDisabled: {
    backgroundColor: "#e0e0e0",
    opacity: 0.7,
  },
  paginationButtonText: {
    fontWeight: "600",
    color: "#000",
  },
  pageNumbers: {
    flexDirection: "row",
    marginHorizontal: 10,
  },
  pageNumbersMobile: {
    flexWrap: "wrap",
    justifyContent: "center",
  },
  pageNumber: {
    width: 35,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 3,
    marginVertical: 5,
    borderRadius: 5,
    backgroundColor: "#f0f0f0",
  },
  currentPageNumber: {
    backgroundColor: "#F5BF4C",
  },
  pageNumberText: {
    fontWeight: "500",
  },
  currentPageNumberText: {
    fontWeight: "700",
  },
  ellipsis: {
    width: 20,
    justifyContent: "center",
    alignItems: "center",
  }
});

export default BlogIndex;