import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;

export default function EnvironmentalBusinessGrants() {
  const navigation = useNavigation();
  
  // Add refs for each section
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={styles.container}>
          {/* Banner */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/EducationGrants/education_grants_3.png")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Education Grants in Canada: Opportunities for Students and Educators</Text>
            </View>
          </View>

          {/* Intro section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
            Canada offers a wide range of education grants that can help ease the financial burden of pursuing further studies, developing educational programs, or enhancing teaching skills. Whether you are a student looking to fund your studies, a teacher seeking professional development, or an institution aiming to support specific programs, there are numerous opportunities to access financial aid. These grants can make a significant difference, often covering tuition costs, course materials, or development initiatives without the need for repayment. Here's what you need to know about education grants in Canada.
            </Text>
          </View>

          <View style={styles.content}>
          <View style={styles.twoColumnLayout}>
                {/* Left Column */}
                <View style={styles.leftColumn}>
                    {/* Table of Contents */}
                    <View style={styles.tocContainer}>
                        <Text style={styles.tocTitle}>Table of contents</Text>
                        <TouchableOpacity onPress={() => scrollToSection(section1)}>
                            <Text style={styles.tocItem}>1. The importance of education grants in Canada</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(section2)}>
                            <Text style={styles.tocItem}>2. Types of education grants available</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(section3)}>
                            <Text style={styles.tocItem}>3. Applying for education grants in Canada</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(section4)}>
                            <Text style={styles.tocItem}>4. Making the most of education grants</Text>
                        </TouchableOpacity>
                    </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

                {/* Right Column - Main Content */}
                <View style={styles.rightColumn}>
                  {/* Section 1 */}
                  <View style={[styles.section]} ref={section1}>
                      <Text style={styles.sectionTitle}>The importance of education grants in Canada</Text>
                      <Image 
                      style={[styles.sideImage, { width: '100%', marginBottom: 20 }]} 
                      source={require("../../../assets/blogs/EducationGrants/education_grants_4.png")}
                        />
                      <Text style={styles.paragraph}>
                        Education costs can be a significant obstacle for students, educators, and institutions. From tuition fees and school supplies to program development and training, the financial demands of the educational sector can be high. Grants serve as a critical support mechanism, providing much-needed funding to make education more accessible and impactful. These grants often come from federal, provincial, and local governments, as well as non-profit organizations, helping to remove financial barriers and promote learning and growth across Canada.
                      </Text>
                    
                  </View>

                  {/* Section 2*/}
                  <View style={[styles.section]} ref={section2}>
                    <Text style={styles.sectionTitle}>Types of Education Grants Available</Text>
                    
                    <Text style={styles.sectionTitle}>For Students:</Text>
                    <View style={styles.listContainer}>
                      <ul style={styles.unorderedList}>
                        <li>
                          <Text style={styles.paragraph}>
                            Canada Student Grants (CSG): Full-time students from low- to middle-income families may receive up to $4,200 a year, part-time students may be eligible for up to $2,520 a year, while students with permanent disabilities may be entitled to additional funding
                          </Text>
                        </li>
                      </ul>
                    </View>

                    <Text style={styles.sectionTitle}>Grants for Indigenous Students:</Text>
                    <View style={styles.listContainer}>
                      <ul style={styles.unorderedList}>
                        <li>
                          <Text style={styles.paragraph}>
                            Indigenous Student Bursary: This bursary gives assistance to Indigenous students in their post-secondary programs through some tuition and other student costs.
                          </Text>
                        </li>
                        <li>
                          <Text style={styles.paragraph}>
                            Post-Secondary Student Support Program (PSSSP): A federated initiative under which financial assistance is granted to select First Nations and eligible Inuit students who pursue or are contemplating pursuing post-secondary education.
                          </Text>
                        </li>
                      </ul>
                    </View>

                    <Text style={styles.sectionTitle}>For Adult Learners and Skills Development:</Text>
                    <View style={styles.listContainer}>
                      <ul style={styles.unorderedList}>
                        <li>
                          <Text style={styles.paragraph}>
                            Canada Training Benefit: Grants are available for training courses to support Canadians enrolling in courses designed to improve skills, upgrade qualifications, or transition into new careers.
                          </Text>
                        </li>
                      </ul>
                    </View>

                    <Text style={styles.sectionTitle}>Grants for Institutions:</Text>
                    <View style={styles.listContainer}>
                      <ul style={styles.unorderedList}>
                        <li>
                          <Text style={styles.paragraph}>
                            Funds available for new or improved development of educational programs such as Innovation Fund for Educational Excellence
                          </Text>
                        </li>
                      </ul>
                    </View>

                    <Text style={styles.sectionTitle}>For Research and Educational Innovation:</Text>
                    <View style={styles.listContainer}>
                      <ul style={styles.unorderedList}>
                        <li>
                          <Text style={styles.paragraph}>
                            Social Sciences and Humanities Research Council (SSHRC): Gives funds for research in social sciences and humanities but mainly in the domain of educational practices and social problems
                          </Text>
                        </li>
                      </ul>
                    </View>
                  </View>
                  </View>
                  </View>



                    <View style={styles.sectionContainer}>
                      <View style={[styles.section, styles.flexRowReverse]} ref={section3}>
                        <Image 
                          style={styles.sideImage} 
                          source={require("../../../assets/blogs/EducationGrants/education_grants_1.png")}
                        />
                        <View style={styles.sideContent}>
                          <Text style={styles.sectionTitle}>Applying for Education Grants in Canada</Text>
                          <Text style={styles.paragraph}>
                                Each education grant comes with its own set of requirements and eligibility criteria. Students, for example, need to ensure they meet the financial need requirements, academic status, and sometimes residency or enrollment conditions set by the grant provider. Applications typically require details about the educational program, proof of enrollment, and financial need documentation.
                            </Text>
                            <Text style={styles.paragraph}>
                                Educators and institutions will need to outline their goals, the impact of their proposed project or development, and how the grant will support these objectives. For grants that focus on professional development or program enhancement, a clear plan outlining the outcomes and benefits of the funding is crucial.
                            </Text>
                            <Text style={styles.paragraph}>
                                To find and apply for education grants, it’s helpful to search through government websites, local educational authorities, and non-profit organizations that provide financial aid. Resources like the Government of Canada’s Student Aid website and provincial education ministry pages are great places to start. Additionally, many universities and colleges have financial aid offices that can help guide students and educators through the grant application process.
                            </Text>
                        </View>
                      </View>

                      <View style={[styles.section, styles.flexRow]} ref={section4}>
                        <Image 
                          style={styles.sideImage} 
                          source={require("../../../assets/blogs/EducationGrants/education_grants_2.png")}
                        />
                        <View style={styles.sideContent}>
                          <Text style={styles.sectionTitle}>Making the Most of Education Grants</Text>
                          <Text style={styles.paragraph}>
                                Education grants in Canada provide invaluable support for students, teachers, and institutions. Whether covering tuition costs, funding professional development, or enabling research projects, these grants can make a lasting impact on education and career opportunities. For students, accessing these funds can make higher education more attainable, while for educators, grants can foster growth and innovation in teaching practices.
                            </Text>
                            <Text style={styles.paragraph}>
                                The key to success is thorough research, understanding eligibility, and crafting a well-prepared application that demonstrates the value and impact of the grant funding. With the right grant, students and educators alike can achieve their educational goals and contribute to the growth of Canada’s learning community.
                            </Text>
                            <Text style={styles.paragraph}>
                                Explore the opportunities available to you today, and take advantage of the grants that can help you or your institution thrive in 2025. 
                            </Text>
                        </View>
                      </View>
          </View>

            

                        {/* <View style={styles.section}>
                            <Text style={styles.text}>
                            For information on funding your green business through environmental grants in Canada, a targeted resource is available at FindGrant.ca's Environmental Business Grants section.
                            This page offers insights into various grants, including those supporting sustainable practices, clean technology, EV infrastructure, and other eco-friendly initiatives.
                            </Text>
                        </View> */}
                        <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={styles.cta}>
                    <Text style={styles.ctaText}>
                        For more information on Canadian grants and to avoid common application mistakes, check out{' '}
                        <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                        <Text style={styles.ctaLink}>FindGrant.ca</Text>
                        </TouchableOpacity>. This site offers an AI-powered matching tool that connects users with available grants across multiple sectors, potentially aiding those in educational fields in finding relevant funding options.
                    </Text>
                    </View>
                        </View>
                    </View>
            <Footer/>
        </ScrollView>
    </>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  headerSection: {
    paddingTop: 10,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 768 ? 'column' : 'row',
    flex: 1,
    paddingTop: 20,
  },
  leftColumn: {
    width: screenWidth < 768 ? '100%' : '25%',
    paddingRight: screenWidth < 768 ? 0 : 20,
    paddingLeft: 20,
    marginBottom: screenWidth < 768 ? 20 : 0,
  },
  rightColumn: {
    width: screenWidth < 768 ? '100%' : '75%',
    paddingRight: 20,
  },
  sectionContainer:{
    padding: 0,
    paddingHorizontal: 20,
  },
  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    marginBottom: 16,

    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  text: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  introText: {
    fontSize: 16,
    lineHeight: 24,  
    padding: screenWidth < 700  ? 0: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 10,
    color: '#011F6F',
    textAlign: 'justify',
  },
  headerImage: {
    width: '100%',
    height: 200,
    marginBottom: 16,
    borderRadius: 8,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },

  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#011F6F',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 14,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedArticlesContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#011F6F',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 15,
  },
  relatedImage: {
    width: '100%',
    height: 120,
    borderRadius: 8,
    marginBottom: 8,
  },
  relatedText: {
    fontSize: 14,
    color: '#011F6F',
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  listContainer: {
    marginLeft: 20,
  },
  unorderedList: {
    marginTop: -5,
    listStyleType: 'disc',
  },
});