import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';

const screenWidth = Dimensions.get('window').width;

export default function EnvironmentalBusinessGrants() {
  const navigation = useNavigation();

  // Add refs for each section
  const sectionRefs = {
    zevip: React.useRef(null),
    cleanTech: React.useRef(null),
    sdtc: React.useRef(null),
    gmf: React.useRef(null),
    natureFund: React.useRef(null),
    agricultural: React.useRef(null),
    isc: React.useRef(null),
    lowCarbon: React.useRef(null),
    nrcan: React.useRef(null),
    navigation: React.useRef(null),
    partnerships: React.useRef(null),
    conclusion: React.useRef(null),

  };

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={styles.container}>
          {/* Banner */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/EnvironmentalGrants/environmental1.png")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Environmental Business Grants: How to Fund Your Green Business in Canada</Text>
            </View>
          </View>

          {/* Intro section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
              Canadian entrepreneurs can access a wide range of grants designed to support environmentally conscious businesses.
              These funding opportunities help reduce the financial burden of starting and operating green businesses,
              providing crucial capital for sustainable initiatives and eco-friendly projects.
            </Text>
          </View>

          <View style={styles.content}>
          <View style={styles.twoColumnLayout}>
                {/* Left Column */}
                <View style={styles.leftColumn}>
                    {/* Table of Contents */}
                    <View style={styles.tocContainer}>
                        <Text style={styles.tocTitle}>Table of contents</Text>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.zevip)}>
                            <Text style={styles.tocItem}>1. Canada's Zero Emission Vehicle Infrastructure Program (ZEVIP)</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.cleanTech)}>
                            <Text style={styles.tocItem}>2. Canada Clean Tech Investment Fund</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.sdtc)}>
                            <Text style={styles.tocItem}>3. Sustainable Development Technology Canada (SDTC)</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.gmf)}>
                            <Text style={styles.tocItem}>4. Green Municipal Fund (GMF)</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.natureFund)}>
                            <Text style={styles.tocItem}>5. Canada Nature Fund</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.agricultural)}>
                            <Text style={styles.tocItem}>6. Agricultural Clean Technology Program</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.isc)}>
                            <Text style={styles.tocItem}>7. Innovative Solutions Canada (ISC) – Clean Tech Stream</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.lowCarbon)}>
                            <Text style={styles.tocItem}>8. The Low Carbon Economy Fund</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.nrcan)}>
                            <Text style={styles.tocItem}>9. Natural Resources Canada (NRCan) Energy Efficiency Programs</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.navigation)}>
                            <Text style={styles.tocItem}>10. Navigating the Application Process</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.partnerships)}>
                            <Text style={styles.tocItem}>11. Leveraging Partnerships and Networks</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => scrollToSection(sectionRefs.conclusion)}>
                            <Text style={styles.tocItem}>12. Conclusion</Text>
                        </TouchableOpacity>
                    </View>

                  {/* Related Articles */}
                  <View style={styles.relatedContainer}>
                    <Text style={styles.relatedTitle}>Related Articles</Text>
                    <TouchableOpacity 
                      style={styles.relatedArticle}
                      onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                    >
                      <Image 
                        style={styles.relatedImage} 
                        source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                      />
                      <Text style={styles.relatedText}>
                        Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                      </Text>
                    </TouchableOpacity>
                  </View>
              </View>

                {/* Right Column - Main Content */}
                <View style={styles.rightColumn}>
                  {/* ZEVIP Section */}
                  <View style={[styles.section, styles.flexRow]} ref={sectionRefs.zevip}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental2.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Canada's Zero Emission Vehicle Infrastructure Program (ZEVIP)</Text>
                      <Text style={styles.paragraph}>
                        The Zero Emission Vehicle Infrastructure Program helps fund the installation of electric vehicle charging stations across Canada.
                        Green businesses that plan to incorporate EV charging solutions or switch to electric fleets can access funding through this program.
                        The grants cover up to 50% of the project costs, encouraging the adoption of electric vehicles and supporting sustainability goals.
                      </Text>
                    </View>
                  </View>

                  {/* Clean Tech Section */}
                  <View style={[styles.section, styles.flexRowReverse]} ref={sectionRefs.cleanTech}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental3.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Canada Clean Tech Investment Fund</Text>
                      <Text style={styles.paragraph}>
                        The Clean Tech Investment Fund supports startups and small businesses that develop clean technology solutions.
                        This fund focuses on projects that reduce carbon emissions, improve energy efficiency, or address environmental challenges.
                        Businesses can use the funding for R&D, scaling operations, or bringing new green technologies to market.
                      </Text>
                    </View>
                  </View>

                  {/* SDTC Section */}
                  <View style={[styles.section, styles.flexRow]} ref={sectionRefs.sdtc}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental4.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Sustainable Development Technology Canada</Text>
                      <Text style={styles.paragraph}>
                      Sustainable Development Technology Canada offers funding to businesses focused on developing and commercializing clean technologies. 
                      The SDTC helps green businesses move from idea to market, supporting innovations that address climate change, reduce waste, and promote 
                      sustainable practices. This grant is ideal for startups in clean energy, waste management, and green manufacturing sectors.
                      </Text>
                    </View>
                  </View>

                  {/* Green Municipal Fund Section */}
                  <View style={[styles.section, styles.flexRowReverse]} ref={sectionRefs.gmf}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental5.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Green Municipal Fund</Text>
                      <Text style={styles.paragraph}>
                            The Green Municipal Fund, managed by the Federation of Canadian Municipalities, supports projects that improve environmental performance in
                            Canadian municipalities. Businesses working on projects that benefit cities—like waste reduction, water conservation, or renewable energy
                            solutions—can access grants or low-interest loans through this program. GMF funding helps green businesses collaborate with municipalities
                            to achieve local sustainability goals.
                      </Text>
                    </View>
                  </View>

                  {/* Canada Nature Fund Section */}
                  <View style={[styles.section, styles.flexRow]} ref={sectionRefs.natureFund}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental6.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Canada Nature Fund</Text>
                      <Text style={styles.paragraph}>
                          For businesses focusing on conservation and biodiversity, the Canada Nature Fund offers grants to projects that protect and restore ecosystems.
                          The fund provides financial support to initiatives that aim to preserve wildlife, restore natural habitats, and enhance biodiversity
                          across Canada. Green businesses engaged in conservation efforts can leverage this funding to expand their operations or launch new projects.
                      </Text>
                    </View>
                  </View>

                  {/* Agri Clean Tech Section */}
                  <View style={[styles.section, styles.flexRowReverse]} ref={sectionRefs.agricultural}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental7.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Agricultural Clean Technology Program</Text>
                      <Text style={styles.paragraph}>
                          The Agricultural Clean Technology Program is designed to help businesses in the agriculture sector adopt clean technologies that reduce
                          environmental impacts. The program offers grants for projects that focus on energy efficiency, soil health, and sustainable farming practices.
                          Farmers and agri-businesses can access funding to invest in technologies that lower their carbon footprint and improve sustainability.
                      </Text>
                    </View>
                  </View>

                  {/* Innovative Solutions Canada Section */}
                  <View style={[styles.section, styles.flexRow]} ref={sectionRefs.isc}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental8.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Innovative Solutions Canada</Text>
                      <Text style={styles.paragraph}>
                        Innovative Solutions Canada has a dedicated Clean Tech Stream that provides grants to businesses developing solutions for environmental challenges.
                        This stream focuses on innovation, encouraging the creation of products and services that improve energy efficiency, reduce emissions, or solve
                        other sustainability-related problems. Companies selected by the ISC can receive funding for R&D and commercialization of their cleantech solutions.
                      </Text>
                    </View>
                  </View>

                  {/* Low Carbon Economy Fund Section */}
                  <View style={[styles.section, styles.flexRowReverse]} ref={sectionRefs.lowCarbon}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental9.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Low Carbon Economy Fund</Text>
                      <Text style={styles.paragraph}>
                        The Low Carbon Economy Fund is a federal initiative that provides funding to projects that reduce greenhouse gas emissions.
                        Businesses that develop or implement technologies or practices that contribute to Canada's climate goals can apply for grants
                        through this program. The fund supports projects across various sectors, including manufacturing, agriculture, and transportation,
                        promoting initiatives that lead to cleaner, more sustainable operations.
                      </Text>
                    </View>
                  </View>

                  {/* Natural Resources Canada Section */}
                  <View style={[styles.section, styles.flexRow]} ref={sectionRefs.nrcan}>
                    <Image 
                      style={styles.sideImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental10.png")}
                    />
                    <View style={styles.sideContent}>
                      <Text style={styles.sectionTitle}>Natural Resources Canada</Text>
                      <Text style={styles.paragraph}>
                        Natural Resources Canada offers a range of energy efficiency programs that provide financial support to businesses implementing
                        energy-saving measures. Whether upgrading equipment, retrofitting buildings, or investing in renewable energy solutions, green businesses
                        can access incentives and grants to reduce their energy consumption. Programs like the Energy Manager Program and Industrial Energy Efficiency
                        Program help companies lower operational costs while contributing to environmental sustainability.
                      </Text>
                    </View>
                  </View>
                  </View>
                  </View>



                    <View style={styles.sectionContainer}>
                      <View style={[styles.section, styles.flexRow]} ref={sectionRefs.navigation}>
                        <Image 
                          style={styles.sideImage} 
                          source={require("../../../assets/blogs/EnvironmentalGrants/environmental11.png")}
                        />
                        <View style={styles.sideContent}>
                          <Text style={styles.sectionTitle}>Navigating the Application Process</Text>
                          <Text style={styles.paragraph}>
                            Applying for environmental grants can be competitive, so preparation is essential. Start by researching the specific requirements and eligibility
                            criteria for each grant program. Develop a clear, detailed business plan that outlines your project, how it aligns with the goals of the grant,
                            and the impact it will have on sustainability. Many grant applications require technical details and cost estimates, so be sure to provide accurate
                            data and projections. Strong applications demonstrate how the project will contribute to Canada's environmental goals while offering a viable
                            business opportunity.
                          </Text>
                        </View>
                      </View>

                      <View style={[styles.section, styles.flexRowReverse]} ref={sectionRefs.partnerships}>
                        <Image 
                          style={styles.sideImage} 
                          source={require("../../../assets/blogs/EnvironmentalGrants/environmental12.png")}
                        />
                        <View style={styles.sideContent}>
                          <Text style={styles.sectionTitle}>Leveraging Partnerships and Networks</Text>
                          <Text style={styles.paragraph}>
                            Forming partnerships with other businesses, municipalities, or research institutions can increase your chances of securing funding.
                            Collaborative projects that involve multiple stakeholders often have a greater impact and can make a stronger case for funding.
                            Networking within the green business community can also help you discover new opportunities, share best practices, and learn from other
                            entrepreneurs who have successfully secured grants.
                          </Text>
                        </View>
                      </View>

                        <View style={styles.section} ref={sectionRefs.conclusion}>
                            <Text style={styles.subtitle}>Conclusion</Text>
                            <Text style={styles.paragraph}>
                            Canada offers a wealth of funding opportunities for green businesses looking to make a positive environmental impact. Whether you're developing clean technologies,
                            improving energy efficiency, or focusing on conservation, these grants can provide the financial support you need to grow your business.
                            By understanding the requirements, preparing a solid business plan, and leveraging networks, entrepreneurs can navigate the application process and access
                            essential funding to build sustainable, successful businesses. With the right approach, Canadian green businesses can contribute to a more sustainable future
                            while achieving long-term growth and profitability.
                            </Text>
                        </View>
          </View>

            

                        {/* <View style={styles.section}>
                            <Text style={styles.text}>
                            For information on funding your green business through environmental grants in Canada, a targeted resource is available at FindGrant.ca's Environmental Business Grants section.
                            This page offers insights into various grants, including those supporting sustainable practices, clean technology, EV infrastructure, and other eco-friendly initiatives.
                            </Text>
                        </View> */}
                        <View style={styles.section}>
                            {/* Call to Action */}
                            <View style={[styles.cta, ]}>
                                <Text style={styles.ctaText}>
                                    For information on funding your green business through environmental grants in Canada, a targeted resource is available at {' '}
                                    <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/EnvironmentalGrants')}>
                                    <Text style={styles.ctaLink}>FindGrant.ca</Text>
                                    </TouchableOpacity>'s Environmental Business Grants section. This page offers insights into various grants, including those supporting sustainable practices, clean technology, EV infrastructure, and other eco-friendly initiatives.
                                </Text>
                            </View>
                        </View>
                    </View>
            <Footer/>
        </ScrollView>
    </>

  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  headerSection: {
    paddingTop: 10,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 768 ? 'column' : 'row',
    flex: 1,
    paddingTop: 20,
  },
  leftColumn: {
    width: screenWidth < 768 ? '100%' : '25%',
    paddingRight: screenWidth < 768 ? 0 : 20,
    paddingLeft: 20,
    marginBottom: screenWidth < 768 ? 20 : 0,
  },
  rightColumn: {
    width: screenWidth < 768 ? '100%' : '75%',
    paddingRight: 20,
  },
  sectionContainer:{
    padding: 0,
    paddingHorizontal: 0,
  },
  section: {
    // marginBottom: 20,
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginTop: 20,
    marginHorizontal: 10,
  },
  title: {
    marginBottom: 16,

    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#011F6F',
    textAlign: screenWidth < 700  ? 'center' : 'left',
  },
  text: {
    fontSize: 16,
    color: '#34495e',
    lineHeight: 24,
    color: '#011F6F',
    textAlign: 'justify',
  },
  introText: {
    fontSize: 16,
    lineHeight: 24,  
    padding: screenWidth < 700  ? 0: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 10,
    color: '#011F6F',
    textAlign: 'justify',
  },
  headerImage: {
    width: '100%',
    height: 200,
    marginBottom: 16,
    borderRadius: 8,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },

  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },

  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    marginBottom: screenWidth < 700  ? 0 : 20,
    alignItems: 'center',
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 20,
    marginHorizontal: screenWidth < 700  ? 0 : 20,
  },
  ctaText: {
    color: '#fff',
    textAlign: screenWidth < 700  ? 'left' : 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#011F6F',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 14,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedArticlesContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#011F6F',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 15,
  },
  relatedImage: {
    width: '100%',
    height: 120,
    borderRadius: 8,
    marginBottom: 8,
  },
  relatedText: {
    fontSize: 14,
    color: '#011F6F',
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
});