import React, {useState, useEffect} from 'react';
import { View, Text, Image, StyleSheet, ScrollView, TouchableOpacity, Dimensions, Linking, SafeAreaView } from 'react-native';
import Header from '../../components/Header';

import Footer from "../../components/Footer"

const screenWidth = Dimensions.get('window').width
const screenHeight = Dimensions.get('window').height

export default function FiveMistakes() {
    
    //Conditional rendering for ChromeBook
    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
    const [screenHeigth, setScreenHeight] = useState(Dimensions.get('window').height);

    useEffect(() => {
        const onChange = ({ window }) => {
        setScreenWidth(window.width); // Update width when the screen size changes
        setScreenHeight(window.heigth); // Update width when the screen size changes
        };

        Dimensions.addEventListener('change', onChange);

        // Cleanup event listener when the component is unmounted
        return () => Dimensions.removeEventListener('change', onChange);
    }, []);

    const isChromeBook = screenWidth === 2560 && screenHeigth === 1080  // ChromeBook width

  return (
    <>
    <Header/>
    
    <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    >
      {/* Banner Section - moved inside ScrollView */}
      <View style={styles.banner}>
        <Image 
          style={styles.bannerImage} 
          source={require("../../../assets/blogs/fiveMistakes/fiveMistakes.jpg")}
        />
        <View style={styles.bannerOverlay}>
          <Text style={styles.bannerText}>5 Mistakes to Avoid When Applying for Canadian Grants</Text>
        </View>
      </View>

      <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Why the Grant Application Process Can Be Challenging</Text>
          <Text style={styles.paragraph}>
            Applying for grants in Canada can be a valuable way to secure funding for your business, education, or community project. However, the process can be competitive and complex, and even small errors can result in missed opportunities. To improve your chances of success, it's essential to understand the common pitfalls and how to avoid them. Here are five mistakes to avoid when applying for Canadian grants.
          </Text>
        </View>

        {/* Content sections with alternating layout */}
        <View style={[styles.section, styles.flexRow]}>
          <Image style={styles.sideImage} source={require("../../../assets/blogs/fiveMistakes/fiveMistakesEligibility.webp")} />
          <View style={styles.sideContent}>
            <Text style={styles.sectionTitle}>1. Ignoring Eligibility Requirements</Text>
            <Text style={styles.paragraph}>
              One of the most critical steps in applying for a grant is ensuring you meet all eligibility requirements. Every grant has its specific criteria, whether it's based on location, industry type, size of business, or purpose of funding. Ignoring or misunderstanding these requirements can lead to wasted time and effort on an application that may not even be considered.
            </Text>
            <Text>
              <Text style={styles.tipLabel}>Tip: </Text>
              <Text style={styles.tipText}>Before you apply, read the eligibility guidelines carefully to ensure that you meet all necessary qualifications. It's also important to confirm that the grant aligns with your project's goals and needs.</Text>
            </Text>
          </View>
        </View>

        <View style={[styles.section, styles.flexRowReverse]}>
          <Image style={styles.sideImage} source={require("../../../assets/blogs/fiveMistakes/incompleteApplication.webp")} />
          <View style={styles.sideContent}>
            <Text style={styles.sectionTitle}>2. Submitting an Incomplete or Inaccurate Application</Text>
            <Text style={styles.paragraph}>
              Grant applications often require detailed information, including financial documents, project plans, budgets, and other supporting materials. One of the most common mistakes is submitting an incomplete or inaccurate application. Missing documents, incorrect information, or sloppy details can hurt your chances of being considered.
            </Text>
            <Text>
              <Text style={styles.tipLabel}>Tip: </Text>
              <Text style={styles.tipText}>Take the time to review all the required documents and double-check the information you provide. If possible, have someone else look over your application before submission to catch any errors or inconsistencies.</Text>
            </Text>
          </View>
        </View>

        <View style={[styles.section, styles.flexRow]}>
          <Image style={styles.sideImage} source={require("../../../assets/blogs/fiveMistakes/fiveMistakesPurpose3.webp")} />
          <View style={styles.sideContent}>
            <Text style={styles.sectionTitle}>3. Failing to Clearly Define the Purpose and Impact of the Grant</Text>
            <Text style={styles.paragraph}>
              A successful grant application clearly outlines how the funding will be used and the impact it will have on your business, project, or community. Many applicants make the mistake of not being specific enough in their proposals. Grant providers want to see how their funding will contribute to a larger goal, whether that's boosting business growth, improving community service, or developing a research project.
            </Text>
            <Text>
              <Text style={styles.tipLabel}>Tip: </Text>
              <Text style={styles.tipText}>Make sure to provide a strong and detailed project plan, complete with objectives, timelines, and measurable outcomes. Clearly explain how the grant will be a catalyst for positive change and why your proposal stands out.</Text>
            </Text>
          </View>
        </View>

        <View style={[styles.section, styles.flexRowReverse]}>
          <Image style={styles.sideImage} source={require("../../../assets/blogs/fiveMistakes/fiveMistakesResearch1.webp")} />
          <View style={styles.sideContent}>
            <Text style={styles.sectionTitle}>4. Not Researching the Grant Provider</Text>
            <Text style={styles.paragraph}>
              Another mistake is failing to research the grant provider and understand their priorities. Every grant has a funder with specific interests, goals, and values, which will influence how they evaluate applications. If you don't tailor your application to align with the grant provider's mission and criteria, your chances of success diminish.
            </Text>
            <Text>
              <Text style={styles.tipLabel}>Tip: </Text>
              <Text style={styles.tipText}>Take the time to understand who the funder is, what their goals are, and what types of projects they have supported in the past. This will help you craft an application that speaks to their objectives and demonstrates that your project is a good fit for their funding.</Text>
            </Text>
          </View>
        </View>

        <View style={[styles.section, styles.flexRow]}>
          <Image style={styles.sideImage} source={require("../../../assets/blogs/fiveMistakes/fiveMistakesDeadline.webp")} />
          <View style={styles.sideContent}>
            <Text style={styles.sectionTitle}>5. Missing Deadlines and Poor Time Management</Text>
            <Text style={styles.paragraph}>
              Applying for grants can be a time-consuming process, and deadlines are strict. One of the most preventable mistakes is missing the application deadline or not allocating enough time to complete the application properly. Many grants have specific windows for submission, and late applications are often not accepted.
            </Text>
            <Text>
              <Text style={styles.tipLabel}>Tip: </Text>
              <Text style={styles.tipText}>To avoid this, start the application process early, allowing plenty of time to gather required documents, write proposals, and revise as needed. Create a schedule with key milestones to ensure that you stay on track and meet all deadlines comfortably.</Text>
            </Text>
          </View>
        </View>



            {/* Conclusion Banner and Section - Outside of columns */}
            <View  style={styles.conclusionContainer}>
              <View style={styles.conclusionBanner}>
                <Image 
                  source={require("../../../assets/blogs/GrantsVsLoan/grantLoan4.webp")} 
                  style={styles.conclusionBannerImage}
                />
                <View style={styles.conclusionBannerOverlay}>
                  <Text style={styles.conclusionBannerText}>CONCLUSION</Text>
                </View>
              </View>
              
              <View style={styles.conclusionContent}>
                <Text style={[styles.paragraph, styles.centeredText]}>
                Applying for Canadian grants requires careful planning, attention to detail, and an understanding of both the requirements and the grant provider's priorities. By avoiding these common mistakes—ignoring eligibility, submitting incomplete applications, lacking a clear purpose, failing to research the funder, and poor time management—you can significantly increase your chances of being awarded funding.
                </Text>
                <Text style={[styles.paragraph, styles.centeredText]}>
                Remember that a well-prepared, clear, and compelling application not only meets all the requirements but also tells a strong story about how the grant will make a meaningful impact. So start early, plan thoroughly, and make sure to put your best foot forward when seeking financial support for your business, educational goals, or community projects in Canada.
                </Text>
              </View>
              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For more information on Canadian grants and to avoid common application mistakes, check out {' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity>.
                            This resource offers guidance on eligibility, crafting strong applications, and managing deadlines effectively.

                        </Text>
                    </View>
                </View>
            </View>
      </View>
      <Footer/>
    </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginTop: 20,
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  tip: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#2d3e50',
    color: '#011F6F',
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  tipLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    // color: '#011F6F',
    color: '#000000',
  },
  tipText: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
  },
});
