import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function GrantsVsLoans () {
  const navigation = useNavigation();

  // Add refs for each section
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);
  const conclusion = React.useRef(null);


  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/WomenInBusiness/womenGrants1.webp")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Women in Business: Grants and Funding Programs for Female Entrepreneurs in Canada</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
                Canada has seen a growing commitment to supporting female entrepreneurs, with a variety of grants and funding programs designed to empower women in business. Whether you are launching a startup, expanding your company, or seeking professional development, these programs provide valuable financial resources to help female-led businesses succeed. Knowing where to look and how to apply for these grants can open doors to new opportunities, allowing you to grow your business and make a greater impact. Here's a guide to some of the top grants and funding programs available for women entrepreneurs in Canada.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(section1)}>
                    <Text style={styles.tocItem}>1. Top Grants and Funding Programs for Female Entrepreneurs in Canada</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section2)}>
                    <Text style={styles.tocItem}>2. Navigating the Application Process</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section3)}>
                    <Text style={styles.tocItem}>3. Tips for Success</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section4)}>
                    <Text style={styles.tocItem}>4. Empowering Women in Business</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Section 1 */}
                <View ref={section1} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/WomenInBusiness/womenInBussiness1.webp")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Top Grants and Funding Programs for Female Entrepreneurs in Canada</Text>
                    <Text style={styles.paragraph}>
                        One of the most notable initiatives for female entrepreneurs is the Women Entrepreneurship Strategy (WES). WES is a federal government program aimed at increasing access to financing, talent, networks, and expertise for women in business. As part of this strategy, the Women Entrepreneurship Fund (WEF) provides up to $100,000 in non-repayable funding to women-owned and -led businesses that are ready to scale. The fund supports a range of activities, from market expansion to product development and hiring.
                        Another valuable program is Futurpreneur Canada's Side Hustle Program, which offers support to young female entrepreneurs looking to grow their side businesses into full-time ventures. The program provides financing of up to $15,000, along with access to mentorship and resources tailored to help women succeed in their industries.
                        For Indigenous women entrepreneurs, the Indigenous Women's Entrepreneurship Fund offers financial support and resources to help start or expand a business. This fund aims to empower Indigenous women by providing capital, mentorship, and training opportunities to enhance their entrepreneurial skills and business acumen.
                        In addition to these federal programs, there are various regional grants and funds available to women entrepreneurs across Canada. For example, SheEO is a global community of women supporting women-led ventures through funding and networking opportunities. SheEO provides zero-interest loans to women entrepreneurs, creating an ecosystem of support that goes beyond just financial assistance. These loans are repaid over five years, and the funds are then reinvested in other female-led businesses.
                        The BDC (Business Development Bank of Canada) also offers programs specifically for women entrepreneurs. The BDC Women in Technology Venture Fund focuses on providing venture capital to tech-based, women-led startups, making it one of the largest funds of its kind globally. The BDC is also committed to supporting women in all sectors through advisory services, business loans, and growth capital.
                    </Text>
                  </View>
                </View>

                {/* Section 2 */}
                <View ref={section2} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/WomenInBusiness/womenGrants1.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Navigating the Application Process</Text>
                    <Text style={styles.paragraph}>
                        When applying for grants and funding programs, it's important to understand the requirements and eligibility criteria. Each program has specific goals and guidelines, so it's essential to align your business with these objectives. Typically, you will need to demonstrate that your business is at least 50% women-owned and led. You may also need to provide detailed business plans, financial statements, and projections, as well as explain how the funding will support your business growth or project goals.
                        Be sure to research each program thoroughly and prepare a compelling application that clearly outlines your business's mission, growth potential, and how you plan to use the funds. This process can be competitive, so take the time to craft a strong proposal and gather all necessary documentation.
                    </Text>
                  </View>
                </View>

                {/* Section 3 */}
                <View ref={section3} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/WomenInBusiness/womenGrants2.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Tips for Success</Text>
                    <Text style={styles.paragraph}>
                        When seeking funding as a female entrepreneur, start by exploring all available options, from federal grants to regional programs. Networking with other female business owners and industry experts can provide valuable insights and connect you to potential funding sources. Consider joining women-focused business associations and online communities to learn more about grant opportunities and best practices for applying.
                        It's also important to stay organized and apply early. Many grants have specific application windows and limited funding, so being proactive can improve your chances of securing financial support. Make sure to tailor each application to the specific program, clearly demonstrating how your business aligns with the fund's objectives and how the funding will be used effectively.
                    </Text>
                </View>
                </View>

                {/* Section 4 */}
                <View ref={section4} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/WomenInBusiness/womenInBusinessTips.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Empowering Women in Business</Text>
                    <Text style={styles.paragraph}>
                        Securing a grant or funding as a female entrepreneur can be transformative, providing the capital needed to launch, grow, or pivot your business. With a range of programs designed specifically for women in business across Canada, there are numerous opportunities to access financial support and gain access to mentorship, networking, and resources. From the Women Entrepreneurship Strategy to regional funds and private programs, female entrepreneurs can find the backing they need to succeed.
                        Take advantage of the available resources and support, and take the next step in building or expanding your business. With the right funding and determination, female entrepreneurs can make a powerful impact on the Canadian business landscape and beyond.
                    </Text>
                  </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View ref={conclusion} style={styles.conclusionContainer}>

              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        To access information on grants and funding programs specifically for female entrepreneurs in Canada, you can visi,{' '}
                        <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/WomanOwnedBusinessGrants')}>
                        <Text style={styles.ctaLink}>FindGrant.ca/WomanOwnedBusinessGrants</Text>
                        </TouchableOpacity> which provides resources to explore grants tailored to women-led businesses. This platform includes information on programs like the Women Entrepreneurship Fund (WEF) and other opportunities for women in the technology, arts, and business sectors.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});
