import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function GrantsVsLoans () {
  const navigation = useNavigation();

  // Add refs for each section
  const step1 = React.useRef(null);
  const step2 = React.useRef(null);
  const step3 = React.useRef(null);
  const step4 = React.useRef(null);
  const step5 = React.useRef(null);
  const step6 = React.useRef(null);
  const step7 = React.useRef(null);
  const step8 = React.useRef(null);
  const conclusion = React.useRef(null);

  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplications1.png")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Step-by-Step Guide to Applying for Grants in Canada</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
            Applying for grants in Canada can be a rewarding process if done correctly. Whether you're seeking funding for a business, education, or community project, understanding how to navigate the application process is crucial. Here's a step-by-step guide to help you apply for grants in Canada successfully.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(step1)}>
                    <Text style={styles.tocItem}>1. Research Available Grants</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(step2)}>
                    <Text style={styles.tocItem}>2. Review Eligibility Criteria</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(step3)}>
                    <Text style={styles.tocItem}>3. Gather Necessary Documentation</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(step4)}>
                    <Text style={styles.tocItem}>4. Write a Strong Proposal</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(step5)}>
                    <Text style={styles.tocItem}>5. Submit Your Application Before the Deadline</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(step6)}>
                    <Text style={styles.tocItem}>6. Follow-Up After Submission</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(step7)}>
                    <Text style={styles.tocItem}>7. Be Prepared for an Interview or Further Steps</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(step8)}>
                    <Text style={styles.tocItem}>8. Track Your Application and Budget</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(conclusion)}>
                    <Text style={styles.tocItem}>9. Conclusion</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Step 1: Research Available Grants */}
                <View ref={step1} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplicationsGuide2.webp")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Step 1: Research Available Grants</Text>
                    <Text style={styles.paragraph}>
                    Begin by researching the different grants available in your sector. Whether it's business, education, or community work, there are grants provided by federal, provincial, and municipal governments, as well as private organizations. Use resources such as the Government of Canada's Grants and Contributions Online Services, Canada Business Network, or specific provincial funding websites to find the right grants for your needs. Look for grants that align with your project goals and ensure you meet the eligibility criteria.
                    </Text>
                  </View>
                </View>

                {/* Step 2: Review Eligibility Criteria */}
                <View ref={step2} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplications2.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Step 2: Review Eligibility Criteria</Text>
                    <Text style={styles.paragraph}>
                    Each grant comes with specific eligibility requirements. These criteria could relate to your project's location, the type of business or organization, its size, or the intended use of the funds. It's crucial to ensure that your project aligns with the grant's goals before proceeding with the application. Review the details thoroughly to confirm that you meet all conditions. Overlooking these requirements can result in wasted effort if you apply for grants that do not fit your project or organization.
                    </Text>
                  </View>
                </View>

                {/* Step 3: Gather Necessary Documentation */}
                <View ref={step3} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplications3.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Step 3: Gather Necessary Documentation</Text>
                    <Text style={styles.paragraph}>
                    Once you've confirmed your eligibility, the next step is to gather all required documentation. Grants usually require supporting materials such as business plans, financial statements, project proposals, and budgets. Make sure you have these documents prepared and organized well before the application deadline. Having accurate and detailed documents can significantly improve the strength of your application.
                    </Text>
                  </View>
                </View>

                {/* Step 4: Write a Strong Proposal */}
                <View ref={step4} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplications4.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Step 4: Write a Strong Proposal</Text>
                    <Text style={styles.paragraph}>
                    Each grant comes with specific eligibility requirements. These criteria could relate to your project's location, the type of business or organization, its size, or the intended use of the funds. It's crucial to ensure that your project aligns with the grant's goals before proceeding with the application. Review the details thoroughly to confirm that you meet all conditions. Overlooking these requirements can result in wasted effort if you apply for grants that do not fit your project or organization.
                    </Text>
                  </View>
                </View>

                {/* Step 5: Submit Your Application Before the Deadline */}
                <View ref={step5} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplications5.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Step 5: Submit Your Application Before the Deadline</Text>
                    <Text style={styles.paragraph}>
                    Grant deadlines are typically strict, and missing them can disqualify your application. Be sure to give yourself ample time to complete the application, gather supporting materials, and review everything before submission. Creating a checklist and timeline for preparing the application will help you stay organized and ensure you don't miss any key components.
                    </Text>
                    <Text style={styles.paragraph}>
                    Submit your application through the required channels, whether it's an online portal or a mailed submission. Double-check that all required fields are completed and all supporting documents are attached.
                    </Text>
                  </View>
                </View>

                {/* Step 6: Follow-Up After Submission */}
                <View ref={step6} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplications6.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Step 6: Follow-Up After Submission</Text>
                    <Text style={styles.paragraph}>
                    After submitting your application, it's good practice to follow up with the granting organization. This can be as simple as confirming that your application was received and asking if there is any additional information you need to provide. Following up shows your commitment and keeps your application top of mind for the grant provider.
                    </Text>
                  </View>
                </View>

                {/* Step 7: Be Prepared for an Interview or Further Steps */}
                <View ref={step7} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplications7.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Step 7: Be Prepared for an Interview or Further Steps</Text>
                    <Text style={styles.paragraph}>
                    Some grants may require an interview or additional steps before the final decision is made. Be prepared to discuss your project in more detail if requested. This could include providing further clarification on your proposal, budget, or impact. Having a well-prepared pitch can make all the difference in securing the funding.
                    </Text>
                  </View>
                </View>

                {/* Step 8: Track Your Application and Budget */}
                <View ref={step8} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplications8.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Step 8: Track Your Application and Budget</Text>
                    <Text style={styles.paragraph}>
                    Once you receive the grant, tracking how the funds are used is crucial. Most grant providers will require reports or updates on how the money is being spent and the progress of your project. Keep detailed records and ensure you meet any reporting requirements to maintain compliance with the grant terms.
                    </Text>
                  </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View ref={conclusion} style={styles.conclusionContainer}>
              <View style={styles.conclusionBanner}>
                <Image 
                  source={require("../../../assets/blogs/GrantApplicationsGuide/grantApplicationsGuide4.webp")} 
                  style={styles.conclusionBannerImage}
                />
                <View style={styles.conclusionBannerOverlay}>
                  <Text style={styles.conclusionBannerText}>CONCLUSION</Text>
                </View>
              </View>
              
              <View style={styles.conclusionContent}>
                <Text style={[styles.paragraph, styles.centeredText]}>
                Applying for grants in Canada can be a time-consuming but rewarding process. By following these steps—researching available grants, ensuring eligibility, preparing a strong proposal, and submitting before the deadline—you can increase your chances of securing valuable funding for your project. Stay organized, be thorough in your application, and align your project's goals with the grant provider's objectives for the best results. With persistence and careful planning, you can access the funding needed to achieve your business, educational, or community goals.
                </Text>
              </View>
              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For guidance on applying for grants, you can access the main,{' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity> page, where they offer AI-powered assistance that helps match users with suitable grants and provides insight into application processes.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});
