import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function GrantsVsLoans () {
  const navigation = useNavigation();
  
  // Add refs for each section
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);
  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/TopGovernment/topGovernment5.png")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Top Government Grants Available for Canadians in 2024</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
            In 2024, Canadians have access to a wide array of government grants designed to help individuals, businesses, and organizations achieve their goals without the burden of debt. These grants, provided by federal, provincial, and local governments, span numerous sectors such as business development, education, housing, sustainability, and community projects. With the right knowledge and a well-prepared application, Canadians can access significant funding to drive their success in the coming year. Here's a detailed overview of the top government grants available for Canadians in 2024.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(section1)}>
                    <Text style={styles.tocItem}>1. Business Grants for Entrepreneurs and Small Businesses</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section2)}>
                    <Text style={styles.tocItem}>2. Housing and Sustainability Grants for Homeowners</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section3)}>
                    <Text style={styles.tocItem}>3. Agricultural Grants for Farmers and Agri-Businesses</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section4)}>
                    <Text style={styles.tocItem}>4. Health and Disability Grants for Canadians</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Section 1 */}
                <View ref={section1} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopGovernment/topGovernment1.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Business Grants for Entrepreneurs and Small Businesses</Text>
                    <Text style={styles.paragraph}>
                        For entrepreneurs and small business owners, 2024 offers several key grants to help start, expand, or innovate their businesses. One of the most prominent programs is the Canada Small Business Financing Program (CSBFP). 
                    </Text>
                    <Text style={styles.paragraph}>
                        This program provides financial assistance to small businesses seeking funding for operational needs, such as purchasing new equipment, upgrading facilities, or expanding operations. It allows small business owners to access loans up to $1 million, which can be used to buy or improve business assets. With the government partially backing these loans, businesses can secure financing that may otherwise be difficult to obtain.
                        Another valuable grant is the Canada Job Grant, which helps businesses cover the costs of training employees to enhance their skills and improve overall productivity. 
                    </Text>
                    <Text style={styles.paragraph}>
                        The program allows employers to apply for funding to train both new and existing employees. It provides coverage for up to two-thirds of training costs, depending on the province, with maximum funding of up to $10,000 per employee. This grant is ideal for businesses looking to invest in their workforce while keeping operational costs manageable.
                        For high-growth businesses, the Business Scale-Up and Productivity Program provides essential funding for companies operating in sectors like technology, clean energy, or manufacturing. This program focuses on businesses that show significant growth potential and are looking to improve their productivity or enter new markets. The funding typically covers up to 50% of project costs and can be used for a range of purposes, including product development, market expansion, and commercialization activities
                    </Text>
                  </View>
                </View>

                {/* Section 2 */}
                <View ref={section2} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopGovernment/topGovernment2.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Housing and Sustainability Grants for Homeowners</Text>
                    <Text style={styles.paragraph}>
                        For Canadians focused on homeownership and sustainability, there are several grant programs available in 2024 that provide financial assistance for purchasing homes and making energy-efficient upgrades. The Canada Greener Homes Grant is a popular program that encourages homeowners to adopt more sustainable living practices by upgrading their homes with energy-efficient improvements. This grant offers up to $5,000 to cover the cost of renovations like installing better insulation, replacing windows and doors, or upgrading heating and cooling systems. 
                    </Text>
                    <Text style={styles.paragraph}>
                        These improvements help reduce energy consumption and lower utility bills while contributing to Canada's broader sustainability goals.
                        First-time homebuyers can benefit from the First-Time Home Buyer Incentive, a program that reduces monthly mortgage payments without increasing down payment requirements. Under this program, the government offers a shared equity mortgage, contributing between 5-10% of the home's purchase price. This reduces the financial burden on first-time buyers, making homeownership more accessible to young Canadians or those entering the housing market for the first time.                    </Text>
                  </View>
                </View>

                {/* Section 3 */}
                <View ref={section3} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopGovernment/topGovernment3.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Agricultural Grants for Farmers and Agri-Businesses</Text>
                    <Text style={styles.paragraph}>
                        Agriculture remains a critical industry in Canada, and the government continues to support farmers and agri-businesses through a variety of grant programs. The Canadian Agricultural Partnership (CAP) is a key program providing financial assistance to farmers looking to adopt new technologies, improve sustainability, or enhance productivity on their farms. 
                    </Text>
                    <Text style={styles.paragraph}>
                        CAP offers funding for a wide range of agricultural projects, including soil and water management, energy efficiency improvements, and market development initiatives.
                        Another important program is Farm Credit Canada (FCC), which offers low-interest loans and grants to help farmers modernize their operations, expand their businesses, or invest in new technology. FCC provides a variety of funding options to support agricultural innovation, sustainability, and growth, making it easier for farmers to remain competitive in the global market.                    </Text>
                  </View>
                </View>

                {/* Section 4 */}
                <View ref={section4} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopGovernment/topGovernment4.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Health and Disability Grants for Canadians</Text>
                    <Text style={styles.paragraph}>
                        The Canadian government also provides grants to support Canadians living with disabilities or health challenges. One of the most well-known programs is the Disability Tax Credit (DTC), which offers tax relief to individuals with disabilities or those supporting someone with a disability. This tax credit helps reduce the amount of income tax owed and can provide significant financial relief to individuals with long-term health conditions.
                        For those looking to save for the future, the Registered Disability Savings Plan (RDSP) is a valuable program that helps individuals with disabilities and their families save for long-term financial security. Contributions to an RDSP are matched by the Canada Disability Savings Grant, with the government contributing up to $3,500 annually and a lifetime limit of $70,000.
                    </Text>
                  </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View style={styles.conclusionContainer}>
              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For an extensive guide on government grants available in Canada for 2025,{' '}
                        <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                        <Text style={styles.ctaLink}>FindGrant.ca</Text>
                        </TouchableOpacity> offers a centralized resource to explore various funding opportunities across sectors such as business, housing, sustainability, and education.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});
