import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function GrantsVsLoans () {
  const navigation = useNavigation();

  // Add refs for each section
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);
  const section5 = React.useRef(null);
  const section6 = React.useRef(null);
  const section7 = React.useRef(null);
  const section8 = React.useRef(null);
  const section9 = React.useRef(null);
  const conclusion = React.useRef(null);

  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/TopIndegenous/topIndigenous2.webp")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Top Indigenous Grants in Canada to Support Business and Community Growth</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
                Indigenous communities and entrepreneurs in Canada can access a wide range of grants designed to encourage business development, innovation, and community growth. These programs aim to close economic gaps, foster entrepreneurship, and support long-term economic self-sufficiency. Whether you're launching a business, expanding operations, or looking to enhance your community's infrastructure, there are funding opportunities available that cater to the unique needs of Indigenous peoples across Canada.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(section1)}>
                    <Text style={styles.tocItem}>1. Indigenous Entrepreneurship Program</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section2)}>
                    <Text style={styles.tocItem}>2. Aboriginal Business and Entrepreneurship Development (ABED)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section3)}>
                    <Text style={styles.tocItem}>3. First Nations Development Fund (Alberta)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section4)}>
                    <Text style={styles.tocItem}>4. Indigenous Skills and Employment Training Program (ISET)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section5)}>
                    <Text style={styles.tocItem}>5. Community Opportunity Readiness Program (CORP)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section6)}>
                    <Text style={styles.tocItem}>6. Métis Nation-Specific Programs</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section7)}>
                    <Text style={styles.tocItem}>7. Canada's Strategic Partnerships Initiative (SPI)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section8)}>
                    <Text style={styles.tocItem}>8. Ulnooweg Development Group</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section9)}>
                    <Text style={styles.tocItem}>9. First Peoples Economic Growth Fund (Manitoba)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(conclusion)}>
                    <Text style={styles.tocItem}>10. Conclusion</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Section 1 */}
                <View ref={section1} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous1.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Indigenous Entrepreneurship Program</Text>
                    <Text style={styles.paragraph}>
                    This program is designed to help Indigenous entrepreneurs start or grow their businesses. It provides grants and access to advisory services to develop business plans, marketing strategies, and operations. Whether you are in the early stages of launching a business or looking to expand, this program offers the support needed to succeed. Funding can be used for a variety of purposes, from purchasing equipment to covering startup costs.
                    </Text>
                  </View>
                </View>

                {/* Section 2 */}
                <View ref={section2} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous2.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Aboriginal Business and Entrepreneurship Development (ABED)</Text>
                    <Text style={styles.paragraph}>
                        The ABED program offers grants and loans specifically to Indigenous businesses. Its focus is on fostering economic development in Indigenous communities by supporting businesses in sectors like technology, tourism, and natural resources. ABED provides financial support for both new ventures and expanding businesses, encouraging Indigenous entrepreneurship while helping to create jobs and sustain community development.
                    </Text>
                  </View>
                </View>

                {/* Section 3 */}
                <View ref={section3} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous4.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>First Nations Development Fund (Alberta)</Text>
                    <Text style={styles.paragraph}>
                        Available to First Nations communities in Alberta, this fund supports projects that enhance economic, social, and cultural well-being. Grants can be used for infrastructure, community programs, business ventures, and cultural projects. The goal of the fund is to empower First Nations communities to pursue sustainable growth, improve the quality of life, and foster business innovation at the local level.
                    </Text>
                </View>
                </View>

                {/* Section 4 */}
                <View ref={section4} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous5.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Indigenous Skills and Employment Training Program (ISET)</Text>
                    <Text style={styles.paragraph}>
                        The ISET program focuses on providing Indigenous people with the skills necessary to succeed in the workforce. Through grants to community organizations, ISET supports training programs that help individuals develop marketable skills in various industries. This program not only helps build career readiness but also promotes long-term economic development by empowering Indigenous communities to participate fully in the Canadian economy.
                    </Text>
                  </View>
                </View>

                {/* Section 5 */}
                <View ref={section5} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous6.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Community Opportunity Readiness Program (CORP)</Text>
                    <Text style={styles.paragraph}>
                        The Community Opportunity Readiness Program is designed to provide financial support for Indigenous communities pursuing economic development projects. CORP helps communities prepare for and implement business ventures that create jobs and foster economic independence. Funding can be used for feasibility studies, business planning, or startup costs. This program encourages Indigenous communities to take control of their economic future by building sustainable businesses.
                    </Text>
                    </View>
                </View>

                {/* Section 6 */}
                <View ref={section6} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous7.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Métis Nation-Specific Programs</Text>
                    <Text style={styles.paragraph}>
                        Several grants and financial resources are available specifically for Métis entrepreneurs and organizations. The Métis Nation of Ontario (MNO) and the Louis Riel Capital Corporation are key providers of business financing and grants for Métis-owned ventures. These programs are designed to assist Métis entrepreneurs in starting and growing their businesses, helping to ensure economic inclusion and opportunity.
                    </Text>
                  </View>
                </View>

                {/* Section 7 */}
                <View ref={section7} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous8.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Canada's Strategic Partnerships Initiative (SPI)</Text>
                    <Text style={styles.paragraph}>
                        The Strategic Partnerships Initiative is a federal program that supports Indigenous involvement in large-scale economic projects across Canada. It facilitates partnerships between Indigenous businesses and industries in sectors like energy, mining, and infrastructure development. Through SPI, Indigenous communities can access funding to help participate in significant national projects, ensuring that they benefit from economic opportunities linked to these industries.
                    </Text>
                    </View>
                </View>

                {/* Section 8 */}
                <View ref={section8} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous9.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Ulnooweg Development Group</Text>
                    <Text style={styles.paragraph}>
                        The Ulnooweg Development Group provides financing and advisory services to Indigenous entrepreneurs in Atlantic Canada. Ulnooweg offers loans, business advisory services, and grants to support Indigenous businesses in a wide range of sectors, including fisheries, manufacturing, and technology. By supporting Indigenous-owned businesses, Ulnooweg helps foster economic development and sustainability within Atlantic Canada's Indigenous communities.
                    </Text>
                  </View>
                </View>

                {/* Section 9 */}
                <View ref={section9} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopIndegenous/topIndigenous10.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>First Peoples Economic Growth Fund (Manitoba)</Text>
                    <Text style={styles.paragraph}>
                        The First Peoples Economic Growth Fund is a Manitoba-based initiative that supports Indigenous businesses through grants, loans, and equity investments. This fund is dedicated to assisting Indigenous entrepreneurs with financing for new business ventures, business expansions, and partnerships. The program also offers funding for feasibility studies, business plan development, and market research, ensuring that Indigenous business owners have the tools they need to succeed.
                    </Text>
                    </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View ref={conclusion} style={styles.conclusionContainer}>
                <View style={styles.conclusionBanner}>
                    <Image 
                    source={require("../../../assets/blogs/GrantsVsLoan/grantLoan4.webp")} 
                    style={styles.conclusionBannerImage}
                    />
                    <View style={styles.conclusionBannerOverlay}>
                    <Text style={styles.conclusionBannerText}>CONCLUSION</Text>
                    </View>
                </View>
                
                <View style={styles.conclusionContent}>
                    <Text style={[styles.paragraph, styles.centeredText]}>
                        Canada offers a wide range of Indigenous-focused grants aimed at supporting business and community growth. These grants are vital for fostering economic development, providing opportunities for entrepreneurship, and promoting self-sufficiency within Indigenous communities. Programs like the Indigenous Entrepreneurship Program, ABED, and the First Nations Development Fund help businesses launch and thrive, while initiatives like ISET and SPI ensure that Indigenous individuals and communities can participate fully in the national economy. By tapping into these resources, Indigenous entrepreneurs and community leaders can build sustainable economic opportunities and contribute to the long-term prosperity of their communities.
                    </Text>
                </View>

              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For information on Indigenous grants in Canada, including programs focused on business and community growth, you can visit the dedicated Indigenous Grants page on,{' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/IndigenousGrants')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity>. This section provides insights into various funding opportunities available for Indigenous entrepreneurs and communities, such as the Indigenous Entrepreneurship Program, the Community Opportunity Readiness Program, and other grants supporting business development, sustainability, and cultural preservation.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});
