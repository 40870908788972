import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Pressable,
  Dimensions,
} from "react-native";
import React, { useState } from "react";
import axios from "axios";

const screenWidth = Dimensions.get("window").width;

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [msgError, setMsgError] = useState("");
  const [ifSuccess, setIfSuccess] = useState(false);
  const emailRegex = /^[a-zA-Z0-9_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleEmailChange = (text) => {
    setEmail(text);
    if (!emailRegex.test(email)) {
      setEmailError("Wrong Email format.");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async () => {
    if (message.length === 0) {
      setMsgError("Message is required!");
    } else if (emailError) {
      return;
    } else {
      setMsgError("");
      const data = {
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
      };

      try {
        const response = await axios.post(
          "https://api.trustnet.site/contact-us",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIfSuccess(true);
        console.log("Success:", response.data);
        alert("Form Submitted\nThank you for contacting us!");
        window.open("/", "_self");
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.infoSection}>
        <Text style={styles.sectionTitle}>
          Got questions or need assistance?
        </Text>
        <Text style={styles.sectionSubtitle}>
          We're here to help
        </Text>
        
        <View style={styles.contactInfo}>
          <Text style={styles.infoLabel}>Email</Text>
          <Text style={styles.infoText}>findgrantca@gmail.com</Text>
          
          <Text style={styles.infoLabel}>Office</Text>
          <Text style={styles.infoText}>20 Dundas Street W, Toronto, Canada</Text>
          <Pressable>
            <Text style={styles.mapLink}>See on google maps →</Text>
          </Pressable>
        </View>
      </View>

      <View style={styles.formSection}>
        <Text style={styles.formTitle}>Contact us</Text>
        
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.label}>Name*</Text>
            <TextInput
              style={styles.input}
              value={name}
              onChangeText={setName}
              placeholderTextColor="#aaa"
            />
          </View>
          
          <View style={styles.formColumn}>
            <Text style={styles.label}>Email*</Text>
            <TextInput
              style={styles.input}
              value={email}
              onChangeText={handleEmailChange}
              placeholderTextColor="#aaa"
            />
            {emailError ? <Text style={styles.errorText}>{emailError}</Text> : null}
          </View>
        </View>
        
        <View style={styles.formRow}>
          <View style={styles.formColumn}>
            <Text style={styles.label}>Phone</Text>
            <TextInput
              style={styles.input}
              value={phone}
              onChangeText={setPhone}
              placeholderTextColor="#aaa"
            />
          </View>
          
          <View style={styles.formColumn}>
            <Text style={styles.label}>Subject</Text>
            <TextInput
              style={styles.input}
              value={subject}
              onChangeText={setSubject}
              placeholderTextColor="#aaa"
            />
          </View>
        </View>
        
        <View>
          <Text style={styles.label}>Message</Text>
          <TextInput
            style={styles.messageInput}
            multiline={true}
            numberOfLines={6}
            value={message}
            onChangeText={setMessage}
            placeholderTextColor="#aaa"
          />
          {msgError ? <Text style={styles.errorText}>{msgError}</Text> : null}
        </View>
        
        <Pressable onPress={handleSubmit} style={styles.submitButton}>
          <Text style={styles.submitText}>Submit</Text>
        </Pressable>
        
        {ifSuccess && <Text style={styles.successText}>Thank you! We will be in touch.</Text>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: "8%",
    paddingBottom: 80,
  },
  infoSection: {
    flex: 1,
    paddingRight: 40,
  },
  formSection: {
    flex: 1,
    backgroundColor: "#0f2878",
    padding: 30,
    borderRadius: 5,
  },
  sectionTitle: {
    fontSize: 28,
    fontWeight: "bold",
    marginBottom: 5,
  },
  sectionSubtitle: {
    fontSize: 28,
    marginBottom: 40,
  },
  contactInfo: {
    marginTop: 40,
  },
  infoLabel: {
    fontSize: 18,
    color: "#666",
    marginTop: 20,
  },
  infoText: {
    fontSize: 18,
    marginTop: 5,
  },
  mapLink: {
    fontSize: 16,
    color: "#000",
    marginTop: 8,
    textDecorationLine: "underline",
  },
  formTitle: {
    fontSize: 28,
    fontWeight: "bold",
    color: "#fff",
    marginBottom: 20,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  formColumn: {
    width: "48%",
  },
  label: {
    fontSize: 16,
    color: "#fff",
    marginBottom: 8,
  },
  input: {
    borderWidth: 1,
    borderColor: "#fff",
    padding: 12,
    borderRadius: 4,
    fontSize: 16,
    backgroundColor: "transparent",
    color: "#fff",
    // These additional styles should help enforce white text in web
    caretColor: "#fff",
  },
  messageInput: {
    borderWidth: 1,
    borderColor: "#fff",
    padding: 12,
    borderRadius: 4,
    fontSize: 16,
    height: 120,
    textAlignVertical: "top",
    backgroundColor: "transparent",
    color: "#fff",
    // These additional styles should help enforce white text in web
    caretColor: "#fff",
  },
  errorText: {
    color: "#ff6b6b",
    fontSize: 14,
    marginTop: 5,
  },
  submitButton: {
    backgroundColor: "#fff",
    borderRadius: 5,
    paddingVertical: 12,
    paddingHorizontal: 25,
    alignSelf: "flex-start",
    marginTop: 20,
  },
  submitText: {
    color: "#0f2878",
    fontSize: 16,
    fontWeight: "bold",
  },
  successText: {
    color: "#fff",
    fontSize: 16,
    marginTop: 15,
  },
});

export default ContactForm;