import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function GrantsVsLoans () {
  const navigation = useNavigation();
  
  // Add refs for each section
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);
  const section5 = React.useRef(null);
  const section6 = React.useRef(null);
  const conclusion = React.useRef(null);

  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter11.webp")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>How Black Entrepreneurs Can Access Grants to Build Thriving Businesses</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
            Black entrepreneurs in Canada face unique challenges when it comes to accessing the financial resources needed to start, grow, and sustain successful businesses. However, several grant programs and funding opportunities are specifically designed to support Black-owned businesses and provide the capital, mentorship, and resources necessary for long-term success. These initiatives are available through government programs, private institutions, and community-based organizations, all working to empower Black entrepreneurs to overcome barriers and thrive in today's economy.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(section1)}>
                    <Text style={styles.tocItem}>1. Black Entrepreneurship Program (BEP)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section2)}>
                    <Text style={styles.tocItem}>2. Futurpreneur Canada – Black Entrepreneur Startup Program</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section3)}>
                    <Text style={styles.tocItem}>3. Regional and Community-Based Grants</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section4)}>
                    <Text style={styles.tocItem}>4. Corporate and Private Sector Grants</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section5)}>
                    <Text style={styles.tocItem}>5. Navigating the Grant Application Process</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section6)}>
                    <Text style={styles.tocItem}>6. Leveraging Community and Business Networks</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(conclusion)}>
                    <Text style={styles.tocItem}>7. Conclusion</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Section 1 */}
                <View ref={section1} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter31.avif")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Black Entrepreneurship Program (BEP)</Text>
                    <Text style={styles.paragraph}>
                    The Black Entrepreneurship Program (BEP) is one of the most important funding initiatives available to Black business owners in Canada. Launched by the federal government in collaboration with Black-led business organizations, the BEP includes several funding streams designed to foster entrepreneurship within the Black community.
                    </Text>
                    <Text style={styles.paragraph}>
                    One of the key elements is the National Ecosystem Fund, which helps Black entrepreneurs access business mentorship, skills development, and financial support. This fund works to strengthen networks that Black entrepreneurs can tap into for ongoing support.
                    </Text>
                    <Text style={styles.paragraph}>
                    In addition to the National Ecosystem Fund, the BEP offers the Black Entrepreneurship Loan Fund, providing loans up to $250,000 to Black entrepreneurs and Black-owned businesses. This loan fund aims to remove the barriers often encountered in mainstream lending markets, making it easier for Black entrepreneurs to access the capital they need to grow their businesses.
                    </Text>
                  </View>
                </View>

                {/* Section 2 */}
                <View ref={section2} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter51.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Futurpreneur Canada – Black Entrepreneur Startup Program</Text>
                    <Text style={styles.paragraph}>
                    For younger Black entrepreneurs between the ages of 18 and 39, the Black Entrepreneur Startup Program offered by Futurpreneur Canada is a valuable resource. This program provides up to $60,000 in startup loans, as well as access to two years of business mentorship.
                    </Text>
                    <Text style={styles.paragraph}>
                    The Black Entrepreneur Startup Program aims to address the unique challenges faced by Black entrepreneurs, such as limited access to capital and lack of mentorship. By offering both financial and advisory support, the program helps young Black entrepreneurs build their businesses with confidence and pursue sustainable growth.
                    </Text>
                  </View>
                </View>

                {/* Section 3 */}
                <View ref={section3} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter61.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Regional and Community-Based Grants</Text>
                    <Text style={styles.paragraph}>
                    Several regional and community-based grants are also available to Black entrepreneurs across Canada. For instance, FACE (Federation of African Canadian Economics) partners with financial institutions to provide loans, grants, and business development services. FACE's mission is to empower Black entrepreneurs with access to tailored financial solutions, mentorship, and business training.
                    </Text>
                    <Text style={styles.paragraph}>
                    Another notable organization is the Black Business and Professional Association (BBPA), which offers various grants, scholarships, and financial literacy programs to foster the success of Black entrepreneurs. The BBPA also hosts the annual Harry Jerome Awards, which recognizes Black excellence in business and offers networking opportunities for entrepreneurs across the country.
                    </Text>
                </View>
                </View>

                {/* Section 4 */}
                <View ref={section4} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter62.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Corporate and Private Sector Grants</Text>
                    <Text style={styles.paragraph}>
                    Several corporate programs in Canada have recognized the importance of supporting Black-owned businesses. The TD Ready Commitment program and RBC Future Launch initiative are two examples of corporate-led efforts to address economic disparities and offer funding to Black-owned businesses.
                    </Text>
                    <Text style={styles.paragraph}>
                    These programs provide financial grants, business advisory services, networking opportunities, and leadership training. Additionally, private foundations like the Canadian Women's Foundation and Women's Entrepreneurship Knowledge Hub (WEKH) focus on supporting Black women entrepreneurs.
                    </Text>
                  </View>
                </View>

                {/* Section 5 */}
                <View ref={section5} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnterRegion.webp")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Navigating the Grant Application Process</Text>
                    <Text style={styles.paragraph}>
                    Successfully accessing grants and funding requires a clear understanding of the application process. Black entrepreneurs should start by researching the available grants and ensuring they meet eligibility criteria.
                    </Text>
                    <Text style={styles.paragraph}>
                    The next step is crafting a strong business plan. Many grant applications require this document, which should outline your business goals, target market, financial projections, and how the funds will be used. Many grant programs also offer mentorship to guide applicants through the process.
                    </Text>
                </View>
                </View>

                {/* Section 6 */}
                <View ref={section6} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/BlackEnterprenuers/blackEnter41.webp")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Leveraging Community and Business Networks</Text>
                    <Text style={styles.paragraph}>
                    Building and leveraging networks is key to increasing the chances of securing funding. Organizations like the Canadian Black Chamber of Commerce and the Black Business Initiative (BBI) offer networking opportunities, mentorship programs, and resources to help Black entrepreneurs connect with investors, partners, and potential customers.
                    </Text>
                    <Text style={styles.paragraph}>
                    Attending events, workshops, and seminars hosted by these organizations can help entrepreneurs stay informed about upcoming funding opportunities, business trends, and best practices.
                    </Text>
                  </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View ref={conclusion} style={styles.conclusionContainer}>
                <View style={styles.conclusionBanner}>
                    <Image 
                    source={require("../../../assets/blogs/GrantsVsLoan/grantLoan4.webp")} 
                    style={styles.conclusionBannerImage}
                    />
                    <View style={styles.conclusionBannerOverlay}>
                    <Text style={styles.conclusionBannerText}>CONCLUSION</Text>
                    </View>
                </View>
                
                <View style={styles.conclusionContent}>
                    <Text style={[styles.paragraph, styles.centeredText]}>
                    Black entrepreneurs in Canada have access to a variety of grants and funding programs designed to help them build thriving businesses. From national programs like the Black Entrepreneurship Program to regional and community-based initiatives, Black business owners can find the financial support and mentorship they need to succeed.
                    </Text>
                    <Text style={[styles.paragraph, styles.centeredText]}>
                    By understanding the grant application process, building strong business plans, and tapping into networks, Black entrepreneurs can overcome challenges and access the resources needed for growth and long-term success.
                    </Text>
                </View>

              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For more information on grants and funding opportunities for Black entrepreneurs, visit the {' '}
                        <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/BlackEntrepreneurshipGrants')}>
                        <Text style={styles.ctaLink}>FindGrant.ca</Text>
                        </TouchableOpacity>'s Black and BIPOC Business Grants page.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});