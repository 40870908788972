import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function StartupGrantsCanada2024 () {
  const navigation = useNavigation();

  // Add refs for each section
  const csbfpRef = React.useRef(null);
  const futurpreneurRef = React.useRef(null);
  const bsppRef = React.useRef(null);
  const iscRef = React.useRef(null);
  const rdasRef = React.useRef(null);
  const cdapRef = React.useRef(null);
  const sredRef = React.useRef(null);
  const wesRef = React.useRef(null);

  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/StartUps/startUp1.png")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Essential Start-Up Grants for Canadian Entrepreneurs in 2024</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
              Canadian entrepreneurs in 2024 can access a wide range of grants designed to support the launch and growth of their businesses.
              These funding opportunities help reduce the financial burden of starting a business, providing crucial capital to cover startup costs, expansion efforts, and innovation projects.
              Here's an overview of some essential grants available for Canadian startups in 2024.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(csbfpRef)}>
                    <Text style={styles.tocItem}>1. Canada Small Business Financing Program (CSBFP)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(futurpreneurRef)}>
                    <Text style={styles.tocItem}>2. Futurpreneur Canada</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(bsppRef)}>
                    <Text style={styles.tocItem}>3. Business Scale-Up and Productivity Program</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(iscRef)}>
                    <Text style={styles.tocItem}>4. Innovative Solutions Canada (ISC)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(rdasRef)}>
                    <Text style={styles.tocItem}>5. Regional Development Agencies (RDAs)</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(cdapRef)}>
                    <Text style={styles.tocItem}>6. Canada Digital Adoption Program</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(sredRef)}>
                    <Text style={styles.tocItem}>7. Scientific Research and Experimental Development</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(wesRef)}>
                    <Text style={styles.tocItem}>8. Women Entrepreneurship Strategy</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Canada Small Business Financing Program (CSBFP) */}
                <View ref={csbfpRef} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/StartUps/startUp1.webp")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Canada Small Business Financing Program (CSBFP)</Text>
                    <Text style={styles.paragraph}>
                      The Canada Small Business Financing Program is a key resource for new entrepreneurs. It helps small businesses secure loans to cover a variety of expenses, including purchasing equipment and property or improving facilities. 
                      While it's a loan guarantee program, not a direct grant, it allows entrepreneurs to access larger loans from financial institutions, making it easier to secure financing with favorable terms.
                    </Text>
                  </View>
                </View>

                {/* Futurpreneur Canada */}
                <View ref={futurpreneurRef} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/StartUps/startUp2.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Futurpreneur Canada</Text>
                    <Text style={styles.paragraph}>
                      For young entrepreneurs, Futurpreneur Canada offers startup loans, mentorship, and resources specifically for those between 18 and 39 years old. 
                      Futurpreneur's support can be essential for startups needing initial capital to get off the ground. In addition to loans, Futurpreneur provides access to a business mentor for two years to guide young entrepreneurs through the early stages of their business journey.
                    </Text>
                  </View>
                </View>

                {/* Business Scale-Up and Productivity Program (BSPP) */}
                <View ref={bsppRef} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/StartUps/startUp3.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Business Scale-Up and Productivity Program (BSPP)</Text>
                    <Text style={styles.paragraph}>
                      The Business Scale-Up and Productivity Program is designed for high-growth startups. This federal initiative provides funding to help businesses scale up operations, improve productivity, and commercialize new products or technologies. 
                      The program focuses on companies in key sectors such as clean technology, manufacturing, and digital industries. Grants cover up to 50% of project costs, making it easier for startups to accelerate their growth while reducing financial risks.
                    </Text>
                  </View>
                </View>

                {/* Innovative Solutions Canada (ISC) */}
                <View ref={iscRef} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/StartUps/startUp4.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Innovative Solutions Canada (ISC)</Text>
                    <Text style={styles.paragraph}>
                      Innovative Solutions Canada (ISC) is a federal program that offers funding to startups developing innovative products or services that address government challenges. 
                      The program is split into two streams: one provides funding for research and development, while the other focuses on helping businesses commercialize their innovations. ISC is a valuable resource for startups in the tech sector, providing both financial support and access to government procurement opportunities.
                    </Text>
                  </View>
                </View>

                {/* Regional Development Agencies (RDAs) */}
                <View ref={rdasRef} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/StartUps/startUp5.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Regional Development Agencies (RDAs)</Text>
                    <Text style={styles.paragraph}>
                      Canada's Regional Development Agencies (RDAs) offer various funding programs tailored to local business needs. Each region of the country has its own RDA, such as FedDev Ontario, Western Economic Diversification Canada, and Atlantic Canada Opportunities Agency. 
                      These agencies provide grants and loans to startups and small businesses working to drive regional economic development. Funding can cover a wide range of activities, including business expansion, innovation, and marketing.
                    </Text>
                  </View>
                </View>

                {/* Canada Digital Adoption Program (CDAP) */}
                <View ref={cdapRef} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/StartUps/startUp6.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Canada Digital Adoption Program (CDAP)</Text>
                    <Text style={styles.paragraph}>
                      The Canada Digital Adoption Program offers funding to help small businesses adopt new digital technologies and enhance their online presence. 
                      For startups looking to improve their e-commerce capabilities, adopt new software, or streamline operations with digital tools, this program provides grants to cover the cost of implementing these technologies.
                    </Text>
                  </View>
                </View>

                {/* Scientific Research and Experimental Development (SR&ED) Tax Incentive */}
                <View ref={sredRef} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/StartUps/startUp7.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Scientific Research and Experimental Development (SR&ED) Tax Incentive</Text>
                    <Text style={styles.paragraph}>
                      The SR&ED program is one of the largest government-backed programs in Canada, offering tax incentives to businesses engaged in research and development. 
                      Startups that are developing new products, technologies, or processes can receive tax credits for eligible R&D expenses. This program supports innovation by offsetting the cost of high-risk projects, making it an essential resource for tech-focused startups.
                    </Text>
                  </View>
                </View>

                {/* Women Entrepreneurship Strategy (WES) */}
                <View ref={wesRef} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/StartUps/startUp8.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Women Entrepreneurship Strategy (WES)</Text>
                    <Text style={styles.paragraph}>
                      The Women Entrepreneurship Strategy is a government initiative aimed at increasing access to financing, mentorship, and skills development for women entrepreneurs. 
                      It includes both loan and grant programs, providing women-led startups with the resources they need to grow their businesses. WES funds projects that promote business growth and innovation, ensuring that women entrepreneurs have equal opportunities to succeed in the Canadian business landscape.
                    </Text>
                  </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View style={styles.conclusionContainer}>
              <View style={styles.conclusionBanner}>
                <Image 
                  source={require("../../../assets/blogs/StartUps/startUp4.webp")} 
                  style={styles.conclusionBannerImage}
                />
                <View style={styles.conclusionBannerOverlay}>
                  <Text style={styles.conclusionBannerText}>CONCLUSION</Text>
                </View>
              </View>
              
              <View style={styles.conclusionContent}>
                <Text style={styles.introParagraph}>
                  In 2024, Canadian entrepreneurs have access to a diverse array of grants and funding programs that can help them launch and grow their businesses. 
                  From government-backed initiatives to region-specific grants, these funding sources provide crucial capital, mentorship, and resources to ensure startup success. 
                  Whether you're in the early stages of building your business or scaling up operations, these grants can help you access the financial support needed to thrive in a competitive market.
                </Text>
              </View>
              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024,{' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/StartUpGrants')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity>'s Start-Up Grants section offers tailored information.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginTop: 20,
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
});
