import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function GrantsVsLoans () {
  const navigation = useNavigation();

  // Add refs for each section
  const grants = React.useRef(null);
  const loans = React.useRef(null);
  const comparing = React.useRef(null);
  const conclusion = React.useRef(null);

  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/GrantsVsLoan/grantsvsloans1.png")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Grants vs. Loans in Canada: Which Financial Option is Right for You?</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
                When looking for funding options in Canada, grants and loans are two of the most common financial tools available. Both have distinct advantages and drawbacks, 
                and the choice between them depends on your financial needs, long-term goals, and ability to meet repayment obligations.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(grants)}>
                    <Text style={styles.tocItem}>1. Grants</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(loans)}>
                    <Text style={styles.tocItem}>2. Loans</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(comparing)}>
                    <Text style={styles.tocItem}>3. Comparing grants and loans</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(conclusion)}>
                    <Text style={styles.tocItem}>4. Conclusion</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Grants */}
                <View ref={grants} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantsVsLoan/grantsvsloans2.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Grants</Text>
                    <Text style={styles.paragraph}>
                        Grants are non-repayable funds provided by the government, private organizations, or non-profits to support specific activities, such as business growth, education, or research.
                        One of the biggest benefits of grants is that they do not need to be repaid, making them a highly attractive option for those who qualify. The Canadian government, for example, offers grants 
                        through programs like the Canada Student Grants (CSG) and various business initiatives, such as the Women Entrepreneurship Strategy.
                        However, grants are highly competitive, and the application process is often lengthy and rigorous. They also typically come with restrictions on how the funds must be used, which can limit flexibility in how the money is applied to your project.
                        Securing a grant requires aligning your goals with the funder's priorities. Grants are often given to projects or initiatives that align with the granting body's mission, such as supporting innovation, education, sustainability, or economic development. 
                        To be successful in obtaining a grant, applicants need to provide a clear and detailed plan for how the funds will be used, outlining measurable goals, timelines, and expected outcomes.
                    </Text>
                  </View>
                </View>

                {/* Loans */}
                <View ref={loans} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantsVsLoan/grantsvsloans3.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Loans</Text>
                    <Text style={styles.paragraph}>
                        Loans, unlike grants, are funds borrowed from a lender such as a bank, credit union, or government program that must be repaid with interest. Loans are often easier to secure than grants because they come with fewer eligibility restrictions, and approval is typically based on your creditworthiness and ability to repay.
                        Government-backed loans, such as the Canada Small Business Financing Program and Canada Student Loans, offer favorable terms for borrowers, including lower interest rates or flexible repayment schedules. Loans offer more flexibility in how funds are used, as they usually come with fewer restrictions than grants, allowing you to apply the money to various business expenses, educational costs, or personal needs.
                        The primary downside of loans is the repayment requirement. Borrowers must repay the loan amount plus interest over a set period, which can add financial pressure in the long term. The interest rates and terms vary depending on the lender, your credit score, and the loan type.
                    </Text>
                  </View>
                </View>

                {/* Comparing grants vs loans */}
                <View ref={comparing} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/GrantsVsLoan/grantsvsloans4.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Comparing Grants and Loans</Text>
                    <Text style={styles.paragraph}>
                    When deciding between grants and loans, it's essential to evaluate your financial situation, the purpose of the funding, and your long-term goals. Grants are ideal if you meet the eligibility criteria and can align your project with the grant provider's objectives.
                    They offer the benefit of non-repayable funding but are harder to secure due to the competitive nature of grant applications and the specific restrictions on how the funds are used.
                    Loans, on the other hand, provide more flexibility and quicker access to funds. They are an excellent option if you need financial support quickly or if you require more control over how the money is spent. However, loans come with repayment obligations and interest costs, which can place a burden on your future finances.
                    Another factor to consider is the timeline. Grants typically require a longer application process, and the funds may take time to be awarded, making them less suitable for urgent financial needs. Loans can usually be obtained more quickly, making them the better option if you need immediate financial support.
                    </Text>
                  </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View ref={conclusion} style={styles.conclusionContainer}>
              <View style={styles.conclusionBanner}>
                <Image 
                  source={require("../../../assets/blogs/GrantsVsLoan/grantLoan4.webp")} 
                  style={styles.conclusionBannerImage}
                />
                <View style={styles.conclusionBannerOverlay}>
                  <Text style={styles.conclusionBannerText}>CONCLUSION</Text>
                </View>
              </View>
              
              <View style={styles.conclusionContent}>
                <Text style={[styles.paragraph, styles.centeredText]}>
                Choosing between a grant and a loan in Canada depends on your specific needs and financial situation. Grants offer the major benefit of non-repayable funding but are highly competitive and come with usage restrictions. They are ideal for those who qualify and are looking for debt-free financial support.
                Loans, while requiring repayment with interest, provide flexibility and quicker access to funds, making them suitable for a wider range of projects and financial needs. 
                By carefully evaluating your goals, financial stability, and the conditions of each option, you can make an informed decision that best supports your long-term success.
                </Text>
              </View>
              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For a comprehensive look at funding options, including both grants and loans, you can visit {' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity>.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});
