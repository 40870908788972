import React from 'react';
import { ScrollView, View, Text, Image, StyleSheet, Dimensions, TouchableOpacity, Linking } from 'react-native';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useNavigation } from '@react-navigation/native';


const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const isChromeBook = screenWidth === 2560 && screenHeight === 1080;

export default function GrantsVsLoans () {
  const navigation = useNavigation();

  // Add refs for each section
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);
  const section5 = React.useRef(null);
  const section6 = React.useRef(null);
  const section7 = React.useRef(null);
  const section8 = React.useRef(null);
  const section9 = React.useRef(null);
  const section10 = React.useRef(null);
  const section11 = React.useRef(null);
  const section12 = React.useRef(null);
  const section13 = React.useRef(null);
  const conclusion = React.useRef(null);

  // Scroll handler function
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
        <Header/>

        <ScrollView style={[isChromeBook ? styles.chromeBook : styles.others]}   
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
        >
          {/* Banner Section - moved inside ScrollView */}
          <View style={styles.banner}>
            <Image 
              style={styles.bannerImage} 
              source={require("../../../assets/blogs/TopResources/topResources2.webp")}
            />
            <View style={styles.bannerOverlay}>
              <Text style={styles.bannerText}>Top Resources for Finding Grants in Each Canadian Province</Text>
            </View>
          </View>

          {/* Introduction paragraph - moved outside of content section */}
          <View style={styles.introSection}>
            <Text style={styles.introParagraph}>
                Securing grants in Canada can significantly help fund businesses, education, or community projects. Each province offers a variety of resources to guide individuals and organizations through the process. Below are the top resources for finding grants in every Canadian province.
            </Text>
          </View>

          <View style={[styles.content, isChromeBook ? styles.chromeBook1 : styles.others]}>
            <View style={styles.twoColumnLayout}>
              {/* Left Column - Table of Contents and Related Articles */}
              <View style={styles.leftColumn}>
                {/* Table of Contents */}
                <View style={styles.tocContainer}>
                  <Text style={styles.tocTitle}>Table of contents</Text>
                  <TouchableOpacity onPress={() => scrollToSection(section1)}>
                    <Text style={styles.tocItem}>1. British Columbia</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section2)}>
                    <Text style={styles.tocItem}>2. Alberta</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section3)}>
                    <Text style={styles.tocItem}>3. Saskatchewan</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section4)}>
                    <Text style={styles.tocItem}>4. Manitoba</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section5)}>
                    <Text style={styles.tocItem}>5. Ontario</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section6)}>
                    <Text style={styles.tocItem}>6. Quebec</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section7)}>
                    <Text style={styles.tocItem}>7. Newfoundland and Labrador</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section8)}>
                    <Text style={styles.tocItem}>8. Nova Scotia</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section9)}>
                    <Text style={styles.tocItem}>9. New Brunswick</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section10)}>
                    <Text style={styles.tocItem}>10. Prince Edward Island</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section11)}>
                    <Text style={styles.tocItem}>11. Yukon</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section12)}>
                    <Text style={styles.tocItem}>12. Northwest Territories</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(section13)}>
                    <Text style={styles.tocItem}>13. Nunavut</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => scrollToSection(conclusion)}>
                    <Text style={styles.tocItem}>14. Conclusion</Text>
                  </TouchableOpacity>
                </View>

                {/* Related Articles */}
                <View style={styles.relatedContainer}>
                  <Text style={styles.relatedTitle}>Related Articles</Text>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('EnvironmentalBusinessGrants')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/EnvironmentalGrants/environmental.avif")}
                    />
                    <Text style={styles.relatedText}>
                      Environmental Business Grants: How to Fund Your Green Business in Canada
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity 
                    style={styles.relatedArticle}
                    onPress={() => navigation.navigate('StartupGrantsCanada2024')}
                  >
                    <Image 
                      style={styles.relatedImage} 
                      source={require("../../../assets/blogs/StartUps/startUp1.webp")}
                    />
                    <Text style={styles.relatedText}>
                      Essential Start-Up Grants for Canadian Entrepreneurs in 2024
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              {/* Right Column - Main Content */}
              <View style={styles.rightColumn}>
                {/* Content sections with alternating layout */}
                {/* Section 1 */}
                <View ref={section1} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources2.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>British Columbia</Text>
                    <Text style={styles.paragraph}>
                    BC Grants offers a comprehensive portal for accessing business, educational, and community grants. For non-profits and small businesses, Innovate BC provides additional funding opportunities focused on innovation and technology. The Province of British Columbia's Official Website also offers details on community development grants, including those aimed at sustainability and arts.
                    </Text>
                  </View>
                </View>

                {/* Section 2 */}
                <View ref={section2} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources3.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Alberta</Text>
                    <Text style={styles.paragraph}>
                        Alberta Innovates is the go-to resource for those in the technology or business sectors looking for funding opportunities. Additionally, the Community Initiatives Program (CIP) provides grants to support community enhancement projects, while the Alberta Foundation for the Arts (AFA) focuses on funding for arts and culture. The Government of Alberta’s website is an excellent starting point for exploring all available provincial funding.
                    </Text>
                  </View>
                </View>

                {/* Section 3 */}
                <View ref={section3} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources4.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Saskatchewan</Text>
                    <Text style={styles.paragraph}>
                        The Saskatchewan Economic Development page provides information on grants for business development and innovation. For community projects, the SaskCulture website lists various grants for arts, heritage, and cultural initiatives. The Saskatchewan Arts Board also offers numerous grants for artists and cultural organizations across the province.
                    </Text>
                </View>
                </View>

                {/* Section 4 */}
                <View ref={section4} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources5.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Manitoba</Text>
                    <Text style={styles.paragraph}>
                        The Province of Manitoba's Government Grants portal is a key resource for finding financial support in areas such as business, education, and community development. Non-profit organizations and those looking for cultural funding can explore the Manitoba Arts Council and Community Places Program for grants related to arts, culture, and facility development.
                    </Text>
                  </View>
                </View>

                {/* Section 5 */}
                <View ref={section5} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources6.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Ontario</Text>
                    <Text style={styles.paragraph}>
                        The Ontario Trillium Foundation (OTF) is one of the most comprehensive resources for grants in Ontario, supporting non-profit organizations and community projects. Ontario Creates also provides financial support for creative industries, including film, television, and publishing. For education and small business grants, visit the Government of Ontario website.
                    </Text>
                    </View>
                </View>

                {/* Section 6 */}
                <View ref={section6} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources7.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Quebec</Text>
                    <Text style={styles.paragraph}>
                        The Secrétariat du Québec aux relations canadiennes (SQRC) offers grants for community projects, while Investissement Québec supports business innovation and expansion through various funding options. For arts and culture, Conseil des arts et des lettres du Québec is the key resource, offering a range of grants to artists and organizations across the province.
                    </Text>
                  </View>
                </View>

                {/* Section 7 */}
                <View ref={section7} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources8.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Newfoundland and Labrador</Text>
                    <Text style={styles.paragraph}>
                        For grants related to business and innovation, InnovateNL provides funding to support startups and companies working in research and development. The Department of Tourism, Culture, Arts and Recreation offers funding opportunities for artists, cultural organizations, and community projects. The Government of Newfoundland and Labrador website is a valuable resource for other provincial grants.
                    </Text>
                    </View>
                </View>

                {/* Section 8 */}
                <View ref={section8} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources9.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Nova Scotia</Text>
                    <Text style={styles.paragraph}>
                        The Community, Culture, and Heritage Department in Nova Scotia offers various grants, especially for cultural, heritage, and community projects. Entrepreneurs can explore funding through Innovacorp and NSBI (Nova Scotia Business Inc.), both offering financial support for businesses at different growth stages.
                    </Text>
                  </View>
                </View>

                {/* Section 9 */}
                <View ref={section9} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources10.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>New Brunswick</Text>
                    <Text style={styles.paragraph}>
                        For business-related grants, Opportunities New Brunswick (ONB) provides a range of funding opportunities, including innovation and export development. The ArtsNB platform is dedicated to offering grants for artists and cultural projects in the province. For community grants, visit the Government of New Brunswick's funding resources page
                    </Text>
                    </View>
                </View>

                {/* Section 10 */}
                <View ref={section10} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources11.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Prince Edward Island</Text>
                    <Text style={styles.paragraph}>
                        Innovation PEI is the primary resource for business and entrepreneurial grants, offering various programs to support growth, research, and development. For community and cultural projects, the PEI Cultural Action Plan outlines several funding options, while the Government of PEI's website lists additional grant opportunities for local organizations.
                    </Text>
                  </View>
                </View>

                {/* Section 11 */}
                <View ref={section11} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources12.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Yukon</Text>
                    <Text style={styles.paragraph}>
                        In Yukon, the Yukon Government's Funding and Grants page provides access to financial resources for businesses, non-profits, and community initiatives. Yukon Arts Centre also offers grants to support local artists and cultural projects. For entrepreneurs, the Yukon Business Development Program offers financial support to help start or grow a business.
                    </Text>
                    </View>
                </View>

                {/* Section 12 */}
                <View ref={section12} style={[styles.section, styles.flexRow]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources13.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Northwest Territories</Text>
                    <Text style={styles.paragraph}>
                        The Government of Northwest Territories (GNWT) Grants and Contributions page is the primary resource for finding funding opportunities across the territory. NWT Arts Council also offers grants for artists and cultural initiatives, while ITI (Industry, Tourism, and Investment) provides business support through various grant programs.
                    </Text>
                  </View>
                </View>

                {/* Section 13 */}
                <View ref={section13} style={[styles.section, styles.flexRowReverse]}>
                  <Image style={styles.sideImage} source={require("../../../assets/blogs/TopResources/topResources14.png")} />
                  <View style={styles.sideContent}>
                    <Text style={styles.sectionTitle}>Nunavut</Text>
                    <Text style={styles.paragraph}>
                        Nunavut Development Corporation and Nunavut Tunngavik Inc. are the top resources for grants focused on business development, cultural initiatives, and community projects. For arts funding, the Nunavut Arts and Crafts Association provides grants to support Inuit artists and cultural preservation projects
                    </Text>
                    </View>
                </View>

              </View>
            </View>

            {/* Conclusion Banner and Section - Outside of columns */}
            <View ref={conclusion} style={styles.conclusionContainer}>
                <View style={styles.conclusionBanner}>
                    <Image 
                    source={require("../../../assets/blogs/GrantsVsLoan/grantLoan4.webp")} 
                    style={styles.conclusionBannerImage}
                    />
                    <View style={styles.conclusionBannerOverlay}>
                    <Text style={styles.conclusionBannerText}>CONCLUSION</Text>
                    </View>
                </View>
                
                <View style={styles.conclusionContent}>
                    <Text style={[styles.paragraph, styles.centeredText]}>
                        Each province and territory in Canada offers a wealth of resources to help individuals and organizations access grants for business, education, and community projects. By exploring these provincial resources, you can find funding opportunities tailored to your specific needs and location. Remember to stay organized, understand eligibility requirements, and tailor your applications to align with the grant provider's goals for the best chance of success.
                    </Text>
                </View>

              {/* FindGrant.ca Resource */}
                {/* <View style={styles.section}>
                    <Text style={styles.text}>
                    For essential startup grants and funding resources specifically geared toward Canadian entrepreneurs in 2024, FindGrant.ca's Start-Up Grants section offers tailored information.
                    </Text>
                </View> */}
                <View style={styles.section}>
                    {/* Call to Action */}
                    <View style={[styles.cta, ]}>
                        <Text style={styles.ctaText}>
                        For a comprehensive resource on finding grants specific to each Canadian province, {' '}
                            <TouchableOpacity onPress={() => Linking.openURL('https://findgrant.ca/')}>
                            <Text style={styles.ctaLink}>FindGrant.ca</Text>
                            </TouchableOpacity> offers tools that help individuals, businesses, and organizations access funding options tailored by region.
                        </Text>
                    </View>
                </View>
            </View>
          </View>
          <Footer/>
        </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
    marginHorizontal: 20,
  },

  //Conditional rendering for ChromeBook
  others: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '100%',
    alignSelf: 'center',
  },
  chromeBook: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '50%',
    alignSelf: 'center',
    showsVerticalScrollIndicator : "false",
  },
  chromeBook1: {
    flex: 1,
    backgroundColor: '#ffffff',
    width: '905px',
    alignSelf: 'center',
  },
  headerWidth:{
    width: screenWidth === 2560 && screenHeight === 1080  ? '50%' : "100%",
    alignSelf: 'center',
  },
    //Conditional rendering for ChromeBook End

  header: {
    // backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifySelf: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    // padding: 15,
    // backgroundColor: '#fff',
    // borderRadius: 10,
    // marginTop: 20,
    // marginHorizontal: 10,
    // paddingHorizontal: 40,

    padding: screenWidth < 700  ? 0: 15,
    paddingHorizontal: screenWidth < 700  ? 0: 40,
    // backgroundColor: '#fff',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  section: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 10,
    marginHorizontal: 10,
  },
  sectionTitle: {
    fontSize: 20,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 16,
    // color: '#011F6F',
    color: '#000000',
    marginBottom: 15,
    textAlign: 'justify',
  },
  image: {
    width: '95%',
    height: screenWidth < 700 ? 230 : screenWidth > 1261 ? 800 : 600,
    marginVertical: 10,
    marginBottom: 20,
    alignSelf: 'center',
    resizeMode: "stretch"
  },
  cta: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    padding: 20,
    borderRadius: 10,
    marginTop:screenWidth < 700  ? 0 : 0,
    alignItems: 'center',
  },
  ctaText: {
    color: '#fff',
    textAlign: 'justify',
    fontSize: 16,
  },
  ctaLink: {
    color: '#fff',
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  footer: {
    backgroundColor: '#2d3e50',
    backgroundColor: "#011F6F",
    paddingVertical: 10,
    alignItems: 'center',
  },
  footerText: {
    color: '#fff',
    fontSize: 14,
  },
  banner: {
    position: 'relative',
    width: '100%',
    height: 300,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  bannerText: {
    color: '#fff',
    fontSize: 36,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flexRow: {
    flexDirection: screenWidth < 700 ? 'column' : 'row',
    gap: 40,
  },
  flexRowReverse: {
    flexDirection: screenWidth < 700 ? 'column' : 'row-reverse',
    gap: 40,
  },
  sideImage: {
    width: screenWidth < 700 ? '100%' : '45%',
    height: screenWidth < 700 ? 230 : 350,
    resizeMode: 'cover',
  },
  sideContent: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    paddingTop: 0,
  },
  twoColumnLayout: {
    flexDirection: screenWidth < 1024 ? 'column' : 'row',
    gap: 40,
  },
  leftColumn: {
    width: screenWidth < 1024 ? '100%' : '25%',
    paddingRight: 20,
  },
  rightColumn: {
    width: screenWidth < 1024 ? '100%' : '75%',
  },
  tocContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
  },
  tocTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  tocItem: {
    fontSize: 16,
    color: '#011F6F',
    marginBottom: 10,
    paddingLeft: 10,
  },
  relatedContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
  },
  relatedTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  relatedArticle: {
    marginBottom: 20,
  },
  relatedImage: {
    width: '100%',
    height: 150,
    borderRadius: 10,
    marginBottom: 10,
  },
  relatedText: {
    fontSize: 16,
    color: '#011F6F',
    fontWeight: '500',
  },
  introSection: {
    backgroundColor: '#fff',
    padding: 20,
    marginTop: 20,
    marginBottom: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  introParagraph: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    lineHeight: 24,
  },
  conclusionContainer: {
    width: '100vw',
    marginTop: 40,
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  },
  conclusionBanner: {
    position: 'relative',
    width: '100%',
    height: 120,
    marginBottom: 20,
  },
  conclusionBannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  conclusionBannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1, 31, 111, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  conclusionBannerText: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  conclusionContent: {
    backgroundColor: '#fff',
    padding: 20,
    width: screenWidth < 1024 ? '100%' : '90%',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
});
