import React, { useState, useRef, useEffect } from "react";
import {
  View,
  TextInput,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Dimensions
} from "react-native";

const screenWidth = Dimensions.get("window").width;

const SearchBarForSearchProgram = ({
  title,
  placeholder,
  items,
  style,
  children,
  onChange,
  defaultValue = "",
  index
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  const dropdownRef = useRef();

  const handleSearch = (text) => {
    setInputValue(text);
    const filteredData = items.filter((option) =>
      option.label.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredItems(filteredData);
    setIsDropdownVisible(true);
  };

  const handleItemSelect = (option) => {
    setInputValue(option.label);
    setIsDropdownVisible(false);
    if (onChange) {
      onChange(option.label);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const getDropdownStyle = () => {
    if (screenWidth < 881) {
      return {
        position: "absolute",
        top: 75,
        left: 0,
        width: "100%",
        zIndex: 1000 - index
      };
    }
    return {
      position: "absolute",
      top: 70,
      width: "90%",
      left: "5%",
      zIndex: 500
    };
  };

  return (
    <View style={[
      styles.outerContainer,
      screenWidth < 881 && isDropdownVisible && { marginBottom: 200 }
    ]}>
      <View style={[styles.container, style]} ref={dropdownRef}>
        <View style={styles.placeholder}>
          <Text style={{ fontWeight: "bold" }}>{title}</Text>
          <TextInput
            value={inputValue}
            onChangeText={handleSearch}
            placeholder={placeholder}
            style={[styles.input, inputValue !== "" && styles.selectedText]}
            onFocus={() => setIsDropdownVisible(true)}
          />
        </View>

        {isDropdownVisible && (
          <View style={[styles.dropdown, getDropdownStyle()]}>
            <FlatList
              data={inputValue === "" ? items : filteredItems}
              keyExtractor={(option) => option.id.toString()}
              renderItem={({ item }) => (
                <TouchableOpacity
                  style={[
                    styles.dropdownItem,
                    hoveredItem === item.id && styles.hoveredItem
                  ]}
                  onPress={() => handleItemSelect(item)}
                  onMouseEnter={() => setHoveredItem(item.id)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <Text>{item.label}</Text>
                </TouchableOpacity>
              )}
            />
          </View>
        )}

        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    position: 'relative',
    width: screenWidth < 881 ? '100%' : 220,
    marginBottom: 10,
    zIndex: 1000,
  },
  container: {
    backgroundColor: "transparent",
    width: "100%",
    minHeight: 70,
  },
  placeholder: {
    paddingLeft: 25,
    paddingTop: 12,
    backgroundColor: "white",
    borderRadius: screenWidth < 881 ? 8 : 35,
  },
  input: {
    paddingTop: 5,
    fontSize: 13,
    color: "grey",
    outlineStyle: "none",
    fontFamily: "OpenSans-Semibold",
  },
  dropdown: {
    backgroundColor: "#FFFFFF",
    maxHeight: 200,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  dropdownItem: {
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  hoveredItem: {
    backgroundColor: "#f5bf4c",
  },
  selectedText: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "OpenSans-Semibold",
  }
});

export default SearchBarForSearchProgram;