import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  ImageBackground,
  Pressable,
  Animated,
  Dimensions,
  useWindowDimensions,
  FlatList,
  ActivityIndicator,
} from "react-native";

//import components
import DropDown from "./SearchBarComponents/DropdownComponent";
import SearchButton from "./SearchBarComponents/SearchButton";
import AllGrantButton from "./SearchBarComponents/AllGrantCheck";
import FadedCircle from "./SearchBarComponents/FadeCircle";
import { useNavigation } from "@react-navigation/native";

const screenWidth = Dimensions.get("window").width;

const background = require("../../assets/background.png");
const SearchBar = () => {
  // State to save the options of each dropdown from the API
  const [dropdownData, setDropdownData] = useState({
    location: [],
    community: [],
    industry: [],
    grants: [],
  });

  // Status for user selection
  const [selectedValues, setSelectedValues] = useState({
    location: "",
    community: "",
    industry: "",
    grants: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const navigation = useNavigation();
  const { width } = useWindowDimensions(); // Get the screen width

  // Function to load questions from the API
  const fetchQuestions = async () => {
    try {
      const response = await fetch(
        "https://api.findgrant.ca/webpage/load-questions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      // Map the questions and save the options to the state
      const mappedData = data.reduce((acc, question) => {
        if (question.question === "My location is...") {
          acc.location = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "I am a...") {
          acc.community = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "My industry is...") {
          acc.industry = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        } else if (question.question === "I am looking for...") {
          acc.grants = question.options.rootWords.map((word, index) => ({
            id: index + 1,
            label: word,
          }));
        }
        return acc;
      }, {});

      setDropdownData(mappedData); // Save options in state
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  // Call the API when the component is mounted
  React.useEffect(() => {
    fetchQuestions();
  }, []);

  // Function to handle selection in dropdowns
  const handleSelectionChange = (type, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  // Function to navigate to ProgramSearch with selected data
  const [query, setQuery] = useState('');
  const [body, setBody] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    console.log("Navigating with values:", selectedValues); // verify
    //navigation.navigate("ProgramSearch", { selectedValues }); // Navigation and data passage
    const { location, community, industry, grants } = selectedValues;
    if (!location && !community && !industry && !grants) {
      setErrorMessage("You must select at least one attribute.");
    } else {
      setErrorMessage(""); // Limpia el mensaje de error
      navigation.navigate("ProgramSearch", { selectedValues });
    }
  };


  return (
    <View style={styles.container_main}>
      {width < 950 ? (
        <View style={styles.container}>
          <View style={{ marginTop: -22 }}>
            <Text style={styles.LargeFont}>Your Dream Begins here</Text>
            <Text style={styles.SmallFont}>
              Start by finding your suitable grants in FindGrant
            </Text>
          </View>

          <View style={[styles.searchContainer]}>
            <View style={[styles.mobileDropdownContainer]}>
              <DropDown
                style={styles.mobileDropdown}
                title="Location"
                placeholder="Search Location"
                items={dropdownData.location}
                onChange={(value) => handleSelectionChange("location", value)}
                index={0}
                isMobile={true}
              />

              <DropDown
                style={styles.mobileDropdown}
                title="Community"
                placeholder="Select your community group"
                items={dropdownData.community}
                onChange={(value) => handleSelectionChange("community", value)}
                index={1}
                isMobile={true}
              />

              <DropDown
                style={styles.mobileDropdown}
                title="Industry"
                placeholder="Select your industry"
                items={dropdownData.industry}
                onChange={(value) => handleSelectionChange("industry", value)}
                index={2}
                isMobile={true}
              />

              <DropDown
                style={styles.mobileDropdown}
                title="Kind of Grant"
                placeholder={'kind of grant you are looking for'}
                items={dropdownData.grants}
                onChange={(value) => handleSelectionChange("grants", value)}
                index={3}
                isMobile={true}
              />
            </View>

            <View style={styles.mobileAllGrantWrapper}>
              <AllGrantButton />
            </View>

          </View>
          {errorMessage ? (
            <Text style={styles.errorText}>{errorMessage}</Text>
          ) : null}
          <SearchButton onPress={handleSearch} />
        </View>
      ) : (
        <View style={styles.container}>
          <View style={styles.circleWrapper}>
            <FadedCircle
              radius={600}
              fadeWidth={20}
              color="#F5BF4C"
              style={{ position: "absolute", right: -550, top: -750, opacity: 0.5 }}
            />
            <FadedCircle
              radius={500}
              fadeWidth={20}
              color="#F5BF4C"
              style={{ position: "absolute", left: -450, top: -30, opacity: 0.5 }}
            />
          </View>
          <View style={{ marginTop: 22 }}>
            <Text style={styles.LargeFont}>Your Dream Begins here</Text>
            <Text style={styles.SmallFont}>
              Start by finding your suitable grants in FindGrant
            </Text>
          </View>

          <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
            <View style={styles.searchContainer}>
              <View style={[styles.searchBarWrapper]}>
                <View style={styles.dropdownWrapper}>
                  <DropDown
                    style={styles.desktopDropdown}
                    title="Location"
                    placeholder="Select your current location"
                    items={dropdownData.location}
                    onChange={(value) => handleSelectionChange("location", value)}
                    index={0}
                    isMobile={false}
                  />
                </View>

                <View style={styles.divider} />

                <View style={styles.dropdownWrapper}>
                  <DropDown
                    style={styles.desktopDropdown}
                    title="Community"
                    placeholder="Select your community group"
                    items={dropdownData.community}
                    onChange={(value) => handleSelectionChange("community", value)}
                    index={1}
                    isMobile={false}
                  />
                </View>

                <View style={styles.divider} />

                <View style={styles.dropdownWrapper}>
                  <DropDown
                    style={styles.desktopDropdown}
                    title="Industry"
                    placeholder="Select your industry"
                    items={dropdownData.industry}
                    onChange={(value) => handleSelectionChange("industry", value)}
                    index={2}
                    isMobile={false}
                  />
                </View>

                <View style={styles.divider} />

                <View style={styles.dropdownWrapper}>
                  <DropDown
                    style={styles.desktopDropdown}
                    title="Kind of Grant"
                    placeholder="kind of grant you are looking for"
                    items={dropdownData.grants}
                    onChange={(value) => handleSelectionChange("grants", value)}
                    index={3}
                    isMobile={false}
                  />
                </View>
              </View>
            </View>
            <View style={styles.allGrantsWrapper}>
              <AllGrantButton />

            </View>
          </View>
          {errorMessage ? (
            <Text style={styles.errorText}>{errorMessage}</Text>
          ) : null}
          <View style={styles.searchButtonWrapper}>
            <SearchButton onPress={handleSearch} />
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container_main: {
    display: "flex",
    marginTop: screenWidth < 580
      ? 50
      : screenWidth < 881
      ? 50
      : screenWidth < 1000
      ? 30
      : screenWidth < 1080
      ? 30
      : screenWidth < 1343
      ? 30
      : 40,
    marginBottom: 100,
    alignItems: "center",
    width: "90%",
    minHeight: 300,
    borderRadius: 20,
    backgroundColor: "white",
    position: 'relative',
    overflow: 'visible',
  },
  container: {
    display: "flex",
    paddingTop: screenWidth < 580
      ? 60
      : screenWidth < 881
      ? 70
      : screenWidth < 1000
      ? 30
      : screenWidth < 1080
      ? 30
      : screenWidth < 1343
      ? 30
      : 40,
    paddingBottom: screenWidth < 580
      ? 20
      : screenWidth < 881
      ? 30
      : screenWidth < 1000
      ? 30
      : screenWidth < 1080
      ? 30
      : screenWidth < 1343
      ? 30
      : 40,
    alignItems: "center",
    width: "100%",
    minHeight: 300,
    borderRadius: 20,
    backgroundColor: "#316EE5",
    opacity: 0.9,
    position: 'relative',
  },
  circleWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    zIndex: -1,
    borderRadius: 20,
    opacity: 0.7,
    maskImage: 'linear-gradient(to bottom, black 0%, black 100%)',
    WebkitMaskImage: 'linear-gradient(to bottom, black 0%, black 100%)',
  },
  LargeFont: {
    fontSize:
      screenWidth < 881
        ? "3vh"
        : screenWidth < 1261
        ? 34
        : screenWidth < 1080
        ? 34
        : 34,
    color: "white",
    marginBottom: 10,
    textAlign: "center",
  },
  SmallFont: {
    fontSize: 14,
    textAlign: "center",
    color: "white",
  },
  searchButtonWrapper: {
    position: 'relative',
    zIndex: -1, // Changed from -1000 to 100 to -1
    marginTop: 10, // Added margin to ensure button is visible below dropdowns
  },

  searchContainer: {
    marginTop: 30,
    width: "80%",
    position: 'relative',
    zIndex: 1000,
  },

  searchBarWrapper: {
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: 35,
    padding: 10,
    alignItems: 'center',
    position: 'relative',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },

  dropdownWrapper: {
    flex: 1,
    position: 'relative',
    zIndex: 1000,
  },

  desktopDropdown: {
    width: '100%',
    backgroundColor: 'transparent',
  },

  divider: {
    width: 1,
    height: '60%',
    backgroundColor: '#E5E5E5',
    marginHorizontal: 10,
  },

  allGrantsWrapper: {
    marginLeft: 10,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileAllGrantWrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 35,
  },

  errorText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat-Bold",
    marginTop: 20,
    fontSize: 20,
    textAlign: "center",
  },
  mobileDropdownContainer: {
    width: '100%',
    gap: 10,
    position: 'relative',
    zIndex: 1000,
  },
  mobileDropdown: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});

function ConvertToProperty(data) {
  return data.split(";").map((data, index) => ({
    id: index + 1,
    label: data.trim(),
  }));
}

export default SearchBar;