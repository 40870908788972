import { useNavigation } from '@react-navigation/native';
import React, {useState, useEffect, useRef} from 'react';
import { TouchableOpacity, FlatList, Image, View, Text, StyleSheet, Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width;

const blogPosts = [
  {
      id: 1,
      title: '5 Mistakes to Avoid When Applying for Canadian Grants',
      image: require("../../assets/blogs/fiveMistakes/fiveMistakes.jpg"),
      link: 'FiveMistakes'
  },
  {
      id: 2,
      title: 'Education Grants in Canada: Opportunities for Students and Educators',
      image: require("../../assets/blogs/EducationGrants/education_grants4.avif"),
      link: 'EducationGrants'
  },
  {
      id: 3,
      title: 'Environmental Business Grants: How to Fund Your Green Business in Canada',
      image: require("../../assets/blogs/EnvironmentalGrants/environmental.avif"),
      link: 'EnvironmentalBusinessGrants'
  },
  {
      id: 4,
      title: 'Essential Start-Up Grants for Canadian Entrepreneurs in 2024',
      image: require("../../assets/blogs/StartUps/startUp4.webp"),
      link: 'StartupGrantsCanada2024'
  },
  {
      id: 5,
      title: 'Grants vs. Loans in Canada: Which Financial Option is Right for You?',
      image: require("../../assets/blogs/GrantsVsLoan/grantLoans1.webp"),
      link: 'GrantsVsLoans'
  },
  {
      id: 6,
      title: 'How Black Entrepreneurs Can Access Grants to Build Thriving Businesses',
      image: require("../../assets/blogs/BlackEnterprenuers/blackEnter11.webp"),
      link: 'BlackEntrepreneursGrants'
  },
  {
      id: 7,
      title: 'How to Find Grants for Non-Profit Organizations in Canada',
      image: require("../../assets/blogs/HowToFind/howTofFind3.webp"),
      link: 'HowToFind'
  },
  {
      id: 8,
      title: 'Small Business Grants in Canada: What You Need to Know',
      image: require("../../assets/blogs/SmallBusiness/smallBusiness1.webp"),
      link: 'SmallBusinessGrantsPost'
  },
  {
      id: 9,
      title: 'Step-by-Step Guide to Applying for Grants in Canada',
      image: require("../../assets/blogs/GrantApplicationsGuide/grantApplicationsGuide1.webp"),
      link: 'GrantApplicationGuide'
  },
  {
      id: 10,
      title: 'Top Funding Sources for Canadian Startups and Entrepreneurs',
      image: require("../../assets/blogs/TopFunding/topFunding1.webp"),
      link: 'TopFundingSources'
  },
  {
      id: 11,
      title: 'Top Government Grants Available for Canadians in 2024',
      image: require("../../assets/blogs/TopGovernment/topGovernment1.webp"),
      link: 'TopGovernmentGrants'
  },
  {
      id: 12,
      title: 'Top Indigenous Grants in Canada to Support Business and Community Growth',
      image: require("../../assets/blogs/TopIndegenous/topIndigenous2.webp"),
      link: 'TopIndigenousGrants'
  },
  {
      id: 13,
      title: 'Top Resources for Finding Grants in Each Canadian Province',
      image: require("../../assets/blogs/TopResources/topResources2.webp"),
      link: 'TopResources'
  },
  {
      id: 14,
      title: 'Women in Business: Grants and Funding Programs for Female Entrepreneurs in Canada',
      image: require("../../assets/blogs/WomenInBusiness/womenGrants1.webp"),
      link: 'WomenInBusiness'
  },
];

export default function BlogPostCarousel() {
    const navigation = useNavigation();
    const flatListRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const renderItem = ({ item }) => (
        <TouchableOpacity
            style={styles.card}
            onPress={() => navigation.navigate(item.link)}
        >
            <Image source={item.image} style={styles.image} />
            <Text style={styles.title}>{item.title}</Text>
            <Text style={styles.readMore}>Read More...</Text>
        </TouchableOpacity>
    );

    return (
        <View style={styles.container}>
            <Text style={styles.sectionHeader}>Featured Blog Posts</Text>
            <FlatList
                data={blogPosts}
                renderItem={renderItem}
                keyExtractor={(item) => item.id.toString()}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                ref={flatListRef}
                onScrollToIndexFailed={(error) => {
                    console.warn('Scroll failed to index:', error);
                }}
                extraData={activeIndex}
                contentContainerStyle={styles.flatlistContainer}
                snapToInterval={270} // Card width + margins
                snapToAlignment="center"
                decelerationRate="fast"
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingVertical: 40,
        backgroundColor: '#f9f9f9',
    },
    sectionHeader: {
        fontSize: 28,
        fontWeight: 'bold',
        textAlign: 'center',
        color: "#011F6F",
        marginBottom: 30,
    },
    flatlistContainer: {
        paddingHorizontal: 20,
    },
    card: {
        backgroundColor: '#fff',
        borderRadius: 15,
        overflow: 'hidden',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 5,
        marginHorizontal: 10,
        width: 250,
        height: 300,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
    },
    image: {
        width: '100%',
        height: 170,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        resizeMode: 'cover'
    },
    title: {
        marginTop: 15,
        marginHorizontal: 15,
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        color: "#011F6F"
    },
    readMore: {
        marginTop: 'auto',
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 'bold',
        color: "#011F6F"
    }
});