import React, { useState, useEffect } from "react";

import { fetchFundingPrograms } from "../../api/FetchFundingPrograms";
import { Text} from "react-native";
import ListGrantsDropDown from "../../components/ListGrantsDropDown";
import { ScrollView } from "react-native-web";


const BlackEntrepreneur = () => {
  const [grantsData, setGrantsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const contentIds = [2493, 60, 146, 576, 1492, 1627, 2123, 2154, 1705, ];
        const contentIds = [2493, 60, 146, 576, 1492, 1627, 2123, 2154, ];
        const fetchedData = await Promise.all(
          contentIds.map((id) => fetchFundingPrograms(id))
        );


        const formattedData = fetchedData.map((grant) => [
          grant.programName,
          grant.programSource,
          grant.description,
          grant.short_urls,
        ]);

        setGrantsData(formattedData);
      } catch (error) {
        console.error("Error fetching Art & Culture grants:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
      <ListGrantsDropDown category="blackEntrepreneur" grantsData={grantsData} />
  );
};

export default BlackEntrepreneur;
